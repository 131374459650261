<i18n lang="yaml">
pt:
  search: 'Encontrar página'
en:
  search: 'Find page'
</i18n>

<template>
  <deck-tooltip
    :disabled="expanded"
    position="bottom"
  >
    <v-text-field
      ref="textInput"
      :aria-label="placeholder"
      hide-details
      :placeholder="placeholder"
      background-color="white"
      color="#a0a0a0"
      class="the-topbar-search-text-input"
      :class="{ 'the-topbar-search-text-input--expanded': expanded }"
      prepend-inner-icon="fa-search fa-regular"
      :append-icon="expanded ? 'fa-times fa-regular' : ''"
      :value="searchTerm"
      @focus="emitFocus"
      @click="emitClick"
      @click:append="$emit('close')"
      @input="$event => $emit('input', $event)"
      @keydown="textInputKeydown"
    />
    <template #content>
      <deck-kbd
        command="cmd+k"
        dark
      />
    </template>
  </deck-tooltip>
</template>

<script>
import { isMac } from '~/assets/javascript/utils';
import DeckKbd from '~/deck/kbd';
import DeckTooltip from '~/deck/tooltip';

export default {
  name: 'TheTopbarSearchTextInput',
  components: {
    DeckKbd,
    DeckTooltip,
  },
  props: {
    searchTerm: { type: String, default: '' },
    expanded: { type: Boolean, required: true },
    placeholder: { type: String, default: null },
  },
  computed: {
    isMac() {
      return isMac();
    },
  },
  watch: {
    expanded(value) {
      if (value) {
        this.$refs.textInput.focus();
      }
    },
  },
  methods: {
    emitFocus() {
      this.$emit('focus');
    },
    emitClick() {
      this.$emit('click');
    },
    textInputKeydown($event) {
      if ($event.key === 'Escape') {
        this.$emit('key-pressed', $event);
        return;
      }

      const commandAndIsMac = $event.metaKey && this.isMac;
      const controlAndIsNotMac = $event.ctrlKey && !this.isMac;

      if ($event.key === 'k' && (commandAndIsMac || controlAndIsNotMac)) {
        this.$emit('key-pressed', $event);
        return;
      }

      if (['ArrowDown', 'ArrowUp', 'Tab'].includes($event.key)) {
        this.$emit('key-pressed', $event);
      }
    },
  },
};
</script>

<style lang="scss">
.the-topbar-search-text-input {
  --append-icon-width: 28px; // 24px icon + 4px padding-left of the .v-input__append-inner

  // The placeholder is not centered when we ommit the `append-icon`, so we simulate its presence with a padding
  .v-text-field__slot {
    padding-right: var(--append-icon-width);
  }

  input {
    font-size: 16;
    max-height: var(--search-height);
  }

  .v-input__append-inner,
  .v-input__prepend-inner {
    align-self: center !important;
    margin: 0 !important;

    .v-icon {
      font-size: 18px !important;
    }
  }

  .v-select__slot,
  .v-input__slot {
    height: var(--search-height) !important;
    transition: var(--search-transition);
    max-height: unset !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 4px !important;

    @media (min-width: 600px) {
      padding: 0 12px !important;
    }

    &::before,
    &::after {
      border: none !important;
    }

    fieldset {
      border: 1px solid #a0a0a0;
    }

    input {
      max-height: unset !important; // v-input override
      height: calc(var(--search-height) - var(--search-border-width) * 2);
      transition: var(--search-transition);

      &[placeholder] {
        text-overflow: ellipsis;
      }

      &::placeholder {
        font-size: 14px;

        @media (min-width: 600px) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}

.the-topbar-search-text-input--expanded  {
  --append-icon-width: 0; // reset the simulated presence from the append icon
}

.the-topbar-search-text-input__shortcut {
  display: flex;
  align-items: center;
}

.the-topbar-search-text-input__shortcut-key {
  --key-height: 20px;

  // Ensures an icon with a minimum 1:1 aspect ratio
  min-width: var(--key-height) !important; // v-icon override

  height: var(--key-height) !important; // v-icon override
  background-color: #E0E0E0;
  padding: 4px 6px;
  margin: 4px 0px;
  border-radius: 4px;
  line-height: 1;
  font-size: 12px !important; // v-icon override
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
}

.the-topbar-search-text-input__shortcut-icon {
  margin: 0px -2px;
  align-self: center;
}
</style>
