
export class Module {
  constructor(nuxt) {
    this.$api = nuxt.$api;
    this.$recaptcha = nuxt.$recaptcha;
    this.router = nuxt.app.router;
  }

  get route() {
    return this.router.currentRoute;
  }

  get tenantSlug() {
    return this.route.params.tenantSlug;
  }

  async sendResetEmail({ email }) {
    return this.$recaptcha.fetchToken(async token => this
      .$api.post('authentication/password_resets', {
        email,
        tenant_slug: this.tenantSlug,
        recaptcha_token: token,
      }));
  }

  async resetPassword({ currentPassword, newPassword, resetToken }) {
    return this.$recaptcha.fetchToken(async (token) => {
      await this.$api.patch('authentication/password_reset', {
        token: resetToken,
        current_password: currentPassword,
        new_password: newPassword,
        recaptcha_token: token,
      });
    });
  }
}

export const ResetPasswordSetup = (nuxt) => {
  const module = new Module(nuxt);

  nuxt.$auth.resetPasswordModule = module;
};
