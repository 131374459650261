import { buildBaseInfoComponent } from './_base';

export const buildGroupInfoComponent = ({
  columns = 1,
  id,
  items,
  label,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    items,
    label,
    type: 'group',
  };

  if (id) component.id = id;

  return component;
};
