import { isEmpty } from 'lodash';

export class MissingRequiredFieldsError extends Error {
  constructor(record, requiredFields) {
    super('Missing required fields');
    this.record = record;
    this.requiredFields = requiredFields;

    this.response = {
      data: {
        errors: ['required_fields_missing'],
      },
    };
  }

  get missingRequiredFields() {
    return this.requiredFields.filter(fieldId => isEmpty(this.record[fieldId]));
  }
}
