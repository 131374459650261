var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TheTopbar',{staticClass:"public-topbar",scopedSlots:_vm._u([{key:"start-content",fn:function(){return [_c('deck-tabs',{attrs:{"value":_vm.currentPageIndex,"size":"large","kind":"fit"}},_vm._l((_vm.pages),function(item,i){return _c('PublicTopbarPageButton',{key:i,attrs:{"item":item,"active-color":_vm.primaryColor,"tab":i}})}),1)]},proxy:true},{key:"end-content",fn:function(){return [_c('deck-button',{attrs:{"is-ready":"","text":_vm.$auth.loggedIn ? _vm.$t('enter') : _vm.$t('login'),"kind":_vm.$auth.loggedIn ? 'secondary' : 'ghost',"to":_vm.localePath({
        name: 't-tenantSlug-login',
        params: {
          tenantSlug: _vm.$route.params.tenantSlug,
        },
      })}}),_vm._v(" "),(!_vm.$auth.loggedIn)?_c('deck-button',{attrs:{"is-ready":"","text":_vm.$t('apply'),"to":_vm.localePath({
        name: 't-tenantSlug-sign-up',
        params: {
          tenantSlug: _vm.$route.params.tenantSlug,
        },
      })}}):_vm._e()]},proxy:true},{key:"menu-content",fn:function(){return [_c('deck-button',{attrs:{"is-ready":"","text":_vm.$auth.loggedIn ? _vm.$t('enter') : _vm.$t('login'),"kind":_vm.$auth.loggedIn ? 'secondary' : 'ghost',"to":_vm.localePath({
        name: 't-tenantSlug-login',
        params: {
          tenantSlug: _vm.$route.params.tenantSlug,
        },
      })}}),_vm._v(" "),(!_vm.$auth.loggedIn)?_c('deck-button',{attrs:{"is-ready":"","text":_vm.$t('apply'),"to":_vm.localePath({
        name: 't-tenantSlug-sign-up',
        params: {
          tenantSlug: _vm.$route.params.tenantSlug,
        },
      })}}):_vm._e(),_vm._v(" "),_c('LinkList',{staticClass:"public-topbar__pages-menu",attrs:{"selected-id":_vm.$route.params.viewId,"items":_vm.pages,"nav":""}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }