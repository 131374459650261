<i18n lang="yaml">
pt:
  open: 'Abrir'
  close: 'Fechar'
  sidebar: 'barra lateral'
en:
  open: 'Open'
  close: 'Close'
  sidebar: 'sidebar'
</i18n>

<template>
  <v-navigation-drawer
    touch
    fixed
    app
    clipped
    width="304"
    :mini-variant="Boolean(miniSidebar)"
    permanent
    :temporary="!miniSidebar && isMobile"
    class="sidebar"
  >
    <div class="sidebar__wrapper">
      <div class="sidebar__category-column">
        <div class="sidebar__category-icons">
          <div class="sidebar__drawer-icon px-3 py-1" />

          <v-tooltip
            right
            transition="slide-x-transition"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="sidebar__category-icon"
                dark
                :aria-label="`${miniSidebar ? $t('open') : $t('close')} ${$t('sidebar')}`"
                icon
                v-bind="attrs"
                v-on="on"
                @click="miniSidebar = !miniSidebar"
              >
                <v-icon>
                  fa-regular fa-bars
                </v-icon>
              </v-btn>
            </template>

            <span>{{ miniSidebar ? $t('open') : $t('close') }} {{ $t('sidebar') }}</span>
          </v-tooltip>
          <v-tooltip
            v-for="({ category_icon, category_name, id }) in categoryHierarchyScoped"
            :key="id"
            right
            transition="slide-x-transition"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="sidebar__category-icon"
                :class="{ 'sidebar__category-icon--active': category_name === currentCategoryName }"
                :aria-label="category_name"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                @click="changeCategory(category_name)"
              >
                <v-icon>
                  {{ category_icon }}
                </v-icon>
              </v-btn>
            </template>

            <span>{{ category_name }}</span>
          </v-tooltip>
        </div>
      </div>

      <div class="sidebar__navigation-column fill-height d-flex flex-column">
        <div v-if="currentCategory" class="sidebar__current-category">
          <v-icon class="sidebar__current-category-icon">
            {{ currentCategory.category_icon }}
          </v-icon>
          <div class="sidebar__current-category-name">
            {{ currentCategory.category_name }}
          </div>
        </div>

        <div class="overflow-y-auto">
          <LinkList
            :selected-id="$route.params.viewId"
            :items="items"
            nav
            @linkClicked="handleLinkClick"
          />
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import LinkList from '~/components/LinkList';

export default {
  name: 'Sidebar',
  components: {
    LinkList,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    ...mapState('viewsMenu', ['views']),
    ...mapState('workspace', ['currentCategoryName']),
    ...mapGetters('workspace', ['categoryHierarchy', 'categoryHierarchyScoped', 'viewsOrder', 'firstScopedViewId', 'currentScope', 'viewInCurrentScope']),
    currentCategory() {
      return this.categoryHierarchy.find(({ category_name: name }) => name === this.currentCategoryName);
    },
    drawerButtonLeftPosition() {
      // mini-variant width: 56px
      // open sidebar width: 304px
      // button width: 24px
      if (this.miniSidebar) return `${56 - 12}px`;
      return `${304 - 12}px`;
    },
    miniSidebar: {
      get() {
        return this.$store.state.viewsMenu.miniSidebar;
      },
      set(v) {
        return this.$store.commit('viewsMenu/setMiniSidebar', v);
      },
    },
    items() {
      if (this.views.length === 0 || this.viewsOrder.length === 0) return [];
      const generateItems = items => items.map((item) => {
        if (item.items) {
          const items = generateItems(item.items);

          if (items.length === 0) return null;

          return {
            id: `${item.group_name}-${item.items[0]?.id}`,
            title: item.group_name,
            active: items.some(i => i.active),
            items,
          };
        }

        const view = this.views.find(({ id }) => id === item.id);

        if (!view || !this.viewInCurrentScope(view)) return null;

        return {
          id: view.id,
          title: view.page_name,
          to: this.localePath({
            name: 't-tenantSlug-m-viewId',
            params: { tenantSlug: this.$route.params.tenantSlug, viewId: view.id },
          }),
          active: view.id === this.$route.params.viewId,
        };
      }).filter(Boolean);

      return generateItems(this.viewsOrder);
    },
  },
  mounted() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler, { passive: true });
  },
  beforeDestroy() {
    this.resetWorkspace();
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    ...mapMutations('workspace', ['resetWorkspace', 'setCurrentCategoryName']),
    resizeHandler() {
      const isMobile = window.innerWidth < 768;

      if (isMobile !== this.isMobile) {
        if (isMobile && !this.miniSidebar) {
          this.miniSidebar = true;
        } else if (!isMobile && this.miniSidebar) {
          this.miniSidebar = window.localStorage.getItem('miniSidebar') || false;
        }
      }

      this.isMobile = isMobile;
    },
    handleLinkClick() {
      if (!this.isMobile) return;
      this.miniSidebar = true;
    },
    changeCategory(categoryName) {
      this.setCurrentCategoryName(categoryName);

      if (this.firstScopedViewId) {
        this.$router.push(this.localeRoute({
          name: 't-tenantSlug-m-viewId',
          params: {
            tenantSlug: this.$route.params.tenantSlug,
            viewId: this.firstScopedViewId,
          },
        }));
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  z-index: 199 !important;
}

.sidebar__zazos-name {
  color: #6b6f75 !important;
  font-family: 'Inter',sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.sidebar__current-category-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 8px;
  align-self: center;
}

.sidebar__current-category-icon {
  border: 2px solid #E3E3E3;
  width: 38px;
  border-radius: 8px;
}

.v-navigation-drawer__border {
  background-color: transparent !important;
}

.sidebar__wrapper {
  --category-column-width: 56px;

  display: flex;
  min-height: 100%;
}

.sidebar__divider {
  margin: 16px 0;
  border-width: 0.5px !important;
  border-color: #9b9b9b !important;
  min-width: 100% !important;
}

.sidebar__category-column {
  flex-shrink: 0;
  width: var(--category-column-width);
  border-right: 1px solid #E3E3E3;
  background-color: #424242;
}

.sidebar__navigation-column {
  width: calc(304px - var(--category-column-width));
}

.sidebar__current-category {
  display: flex;
  height: 36px;
  margin: 14px 12px;
}

.sidebar__category-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar__category-icon {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 8px 0px;
  width: 32px;
  color: white;
}

.sidebar__category-icon--active {
  background-color: white !important;
  color: #6b6f75 !important;
}
</style>
