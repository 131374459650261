<i18n lang="yaml">
pt:
  seeAs: 'Ver como'
  editGroups: 'Gerenciar grupos'
en:
  seeAs: 'See as'
  editGroups: 'Manage groups'
</i18n>

<template>
  <TheTopbarSwitchMenu v-if="show" :items="items" :selected-item="currentScope" :subheader="$t('seeAs')" :aria-label="$t('seeAs')" />
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import TheTopbarSwitchMenu from '~/components/topbars/the-topbar/_switch-menu';
import { viewToRedirectAfterScopeChange } from '~/assets/javascript/utils';

export default {
  name: 'MemberTopbarGroupSwitch',
  components: {
    TheTopbarSwitchMenu,
  },
  computed: {
    ...mapGetters('workspace', ['defaultScope', 'currentScope', 'availableScopes', 'viewInCurrentScope']),
    ...mapState('viewsMenu', ['views']),
    ...mapState('workspace', ['currentCategoryName']),
    show() {
      return this.availableScopes.length > 1 || this.showEditGroup;
    },
    showEditGroup() {
      return this.$auth.user.role === 'admin';
    },
    items() {
      const scopes = this.availableScopes.map(scope => ({
        ...scope,
        on: {
          click: () => this.setCurrentScope(scope),
        },
      }));

      if (this.showEditGroup) {
        scopes.push({
          key: 'edit-groups',
          title: this.$t('editGroups'),
          icon: 'fa-regular fa-users-gear',
          divider: true,
          on: {
            click: () => this.$router.push(this.localeRoute({
              name: 't-tenantSlug-studio-settings-user-groups',
              params: {
                tenantSlug: this.$route.params.tenantSlug,
              },
            })),
          },
        });
      }

      return scopes;
    },
  },
  mounted() {
    if (!this.currentScope) this.setDefaultScope();
  },
  methods: {
    ...mapMutations('workspace', ['setDefaultScope']),
    ...mapActions('workspace', ['updateCurrentScope']),
    setCurrentScope(scope) {
      this.updateCurrentScope({ scope }).then(
        async () => {
          const redirectToView = viewToRedirectAfterScopeChange(this.views, this.viewInCurrentScope, this.$route.params?.viewId, this.currentCategoryName);

          if (redirectToView) {
            await this.$router.push(this.localePath({ name: 't-tenantSlug-m-viewId', params: { tenantSlug: this.$route.params.tenantSlug, viewId: redirectToView.id } }));
          }
        },
      );
    },
  },
};
</script>
