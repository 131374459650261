import { apps } from './apps';
import { categories } from './categories';
import { schema } from './schema';
import { secrets } from './secrets';
import { views } from './views';
import { workflowExecutions } from './workflowExecutions';

export const builder = $axios => ({
  apps: apps($axios),
  categories: categories($axios),
  schema: schema($axios),
  views: views($axios),
  workflowExecutions: workflowExecutions($axios),
  secrets: secrets($axios),
});
