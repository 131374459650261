import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e96a5f4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _db69839a = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _620f89b2 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))

const _2e25f878 = () => interopDefault(import('../pages/set-workspace.vue' /* webpackChunkName: "pages/set-workspace" */))
const _1263cf48 = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _2c56407e = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _b8369494 = () => interopDefault(import('../pages/store/apps/_appName.vue' /* webpackChunkName: "pages/store/apps/_appName" */))
const _206fa2d4 = () => interopDefault(import('../pages/t/_tenantSlug/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/index" */))
const _0229198e = () => interopDefault(import('../pages/t/_tenantSlug/email-validation/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/email-validation/index" */))
const _73f1a270 = () => interopDefault(import('../pages/t/_tenantSlug/login/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/login/index" */))
const _1c11b474 = () => interopDefault(import('../pages/t/_tenantSlug/m/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/index" */))
const _4a806692 = () => interopDefault(import('../pages/t/_tenantSlug/p/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/index" */))
const _b8b02676 = () => interopDefault(import('../pages/t/_tenantSlug/reset-password.vue' /* webpackChunkName: "pages/t/_tenantSlug/reset-password" */))
const _4b8ddb9c = () => interopDefault(import('../pages/t/_tenantSlug/sign-up/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sign-up/index" */))
const _3a40e7c5 = () => interopDefault(import('../pages/t/_tenantSlug/studio/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/index" */))
const _144a1a10 = () => interopDefault(import('../pages/t/_tenantSlug/m/account-configuration.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/account-configuration" */))
const _162c1e00 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings" */))
const _582bb6fa = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/index" */))
const _01e36e3a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/app-builder/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/app-builder/index" */))
const _2eecfffe = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/index" */))
const _22326f9f = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/internal/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/internal/index" */))
const _24228bda = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/system-entities/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/system-entities/index" */))
const _0094d3fc = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/tokens/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/tokens/index" */))
const _5c4bf64a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/index" */))
const _4c0ad67c = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/index" */))
const _69ccd818 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/new" */))
const _766ce2ae = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/_id" */))
const _727fce9e = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/internal/_dummy-data/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/internal/_dummy-data/index" */))
const _1299647a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/tokens/_api-tokens-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/tokens/_api-tokens-card/index" */))
const _56170332 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_id" */))
const _6feb3150 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index" */))
const _06e360a8 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index" */))
const _4d03c335 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_active-members-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_active-members-card/index" */))
const _8820a792 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index" */))
const _3c98d3cc = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id" */))
const _2d4cbdc6 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/index" */))
const _055c69c2 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/history.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/history" */))
const _a39dda9c = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id" */))
const _69f57fe2 = () => interopDefault(import('../pages/t/_tenantSlug/studio/pages/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/pages/_id" */))
const _5d329824 = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/index" */))
const _739c8532 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/index" */))
const _1420ad55 = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/new" */))
const _dc41fcba = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/_recordId/index" */))
const _5ecd4b34 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/r/_recordId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _98173ca8,
    name: "index___en"
  }, {
    path: "/login",
    component: _5e96a5f4,
    name: "login___pt"
  }, {
    path: "/magic-link",
    component: _db69839a,
    name: "magic-link___pt"
  }, {
    path: "/reset-password",
    component: _620f89b2,
    name: "reset-password___pt"
  }, {
    path: "/reset-password",
    redirect: {"name":"set-tenant___pt"}
  }, {
    path: "/set-workspace",
    component: _2e25f878,
    name: "set-workspace___pt"
  }, {
    path: "/sign-up",
    component: _1263cf48,
    name: "sign-up___pt"
  }, {
    path: "/store",
    component: _2c56407e,
    name: "store___pt"
  }, {
    path: "/builder/views",
    redirect: {"name":"studio-pages-id___pt"}
  }, {
    path: "/en/login",
    component: _5e96a5f4,
    name: "login___en"
  }, {
    path: "/en/magic-link",
    component: _db69839a,
    name: "magic-link___en"
  }, {
    path: "/en/reset-password",
    component: _620f89b2,
    name: "reset-password___en"
  }, {
    path: "/en/reset-password",
    redirect: {"name":"set-tenant___en"}
  }, {
    path: "/en/set-workspace",
    component: _2e25f878,
    name: "set-workspace___en"
  }, {
    path: "/en/sign-up",
    component: _1263cf48,
    name: "sign-up___en"
  }, {
    path: "/en/store",
    component: _2c56407e,
    name: "store___en"
  }, {
    path: "/en/builder/views",
    redirect: {"name":"studio-pages-id___en"}
  }, {
    path: "/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___pt"}
  }, {
    path: "/en/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___en"}
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___pt"
  }, {
    path: "/en/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___en"}
  }, {
    path: "/en/store/apps/:appName?",
    component: _b8369494,
    name: "store-apps-appName___en"
  }, {
    path: "/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___pt"}
  }, {
    path: "/en/t/:tenantSlug",
    component: _206fa2d4,
    name: "t-tenantSlug___en"
  }, {
    path: "/store/apps/:appName?",
    component: _b8369494,
    name: "store-apps-appName___pt"
  }, {
    path: "/en/t/:tenantSlug?/email-validation",
    component: _0229198e,
    name: "t-tenantSlug-email-validation___en"
  }, {
    path: "/en/t/:tenantSlug?/login",
    component: _73f1a270,
    name: "t-tenantSlug-login___en"
  }, {
    path: "/en/t/:tenantSlug?/m",
    component: _1c11b474,
    name: "t-tenantSlug-m___en"
  }, {
    path: "/en/t/:tenantSlug?/p",
    component: _4a806692,
    name: "t-tenantSlug-p___en"
  }, {
    path: "/en/t/:tenantSlug?/reset-password",
    component: _b8b02676,
    name: "t-tenantSlug-reset-password___en"
  }, {
    path: "/en/t/:tenantSlug?/sign-up",
    component: _4b8ddb9c,
    name: "t-tenantSlug-sign-up___en"
  }, {
    path: "/en/t/:tenantSlug?/studio",
    component: _3a40e7c5,
    name: "t-tenantSlug-studio___en"
  }, {
    path: "/en/t/:tenantSlug?/m/account-configuration",
    component: _144a1a10,
    name: "t-tenantSlug-m-account-configuration___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/settings",
    component: _162c1e00,
    children: [{
      path: "",
      component: _582bb6fa,
      name: "t-tenantSlug-studio-settings___en"
    }, {
      path: "app-builder",
      component: _01e36e3a,
      name: "t-tenantSlug-studio-settings-app-builder___en"
    }, {
      path: "integrations",
      component: _2eecfffe,
      name: "t-tenantSlug-studio-settings-integrations___en"
    }, {
      path: "internal",
      component: _22326f9f,
      name: "t-tenantSlug-studio-settings-internal___en"
    }, {
      path: "system-entities",
      component: _24228bda,
      name: "t-tenantSlug-studio-settings-system-entities___en"
    }, {
      path: "tokens",
      component: _0094d3fc,
      name: "t-tenantSlug-studio-settings-tokens___en"
    }, {
      path: "user-groups",
      component: _5c4bf64a,
      name: "t-tenantSlug-studio-settings-user-groups___en"
    }, {
      path: "users",
      component: _4c0ad67c,
      name: "t-tenantSlug-studio-settings-users___en"
    }, {
      path: "user-groups/new",
      component: _69ccd818,
      name: "t-tenantSlug-studio-settings-user-groups-new___en"
    }, {
      path: "integrations/:id",
      component: _766ce2ae,
      name: "t-tenantSlug-studio-settings-integrations-id___en"
    }, {
      path: "internal/:dummy-data",
      component: _727fce9e,
      name: "t-tenantSlug-studio-settings-internal-dummy-data___en"
    }, {
      path: "tokens/:api-tokens-card",
      component: _1299647a,
      name: "t-tenantSlug-studio-settings-tokens-api-tokens-card___en"
    }, {
      path: "user-groups/:id?",
      component: _56170332,
      name: "t-tenantSlug-studio-settings-user-groups-id___en"
    }, {
      path: "user-groups/:user-group-form",
      component: _6feb3150,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___en"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _06e360a8,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___en"
    }, {
      path: "users/:active-members-card",
      component: _4d03c335,
      name: "t-tenantSlug-studio-settings-users-active-members-card___en"
    }, {
      path: "users/:users-data-table-card",
      component: _8820a792,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/automations/:id?",
    component: _3c98d3cc,
    children: [{
      path: "",
      component: _2d4cbdc6,
      name: "t-tenantSlug-studio-automations-id___en"
    }, {
      path: "history",
      component: _055c69c2,
      name: "t-tenantSlug-studio-automations-id-history___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id?",
    component: _a39dda9c,
    name: "t-tenantSlug-studio-data-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/pages/:id?",
    component: _69f57fe2,
    name: "t-tenantSlug-studio-pages-id___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId",
    component: _5d329824,
    name: "t-tenantSlug-m-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId",
    component: _739c8532,
    name: "t-tenantSlug-p-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/new",
    component: _1420ad55,
    name: "t-tenantSlug-m-viewId-r-new___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _dc41fcba,
    name: "t-tenantSlug-m-viewId-r-recordId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _5ecd4b34,
    name: "t-tenantSlug-p-viewId-r-recordId___en"
  }, {
    path: "/t/:tenantSlug",
    component: _206fa2d4,
    name: "t-tenantSlug___pt"
  }, {
    path: "/t/:tenantSlug?/email-validation",
    component: _0229198e,
    name: "t-tenantSlug-email-validation___pt"
  }, {
    path: "/t/:tenantSlug?/login",
    component: _73f1a270,
    name: "t-tenantSlug-login___pt"
  }, {
    path: "/t/:tenantSlug?/m",
    component: _1c11b474,
    name: "t-tenantSlug-m___pt"
  }, {
    path: "/t/:tenantSlug?/p",
    component: _4a806692,
    name: "t-tenantSlug-p___pt"
  }, {
    path: "/t/:tenantSlug?/reset-password",
    component: _b8b02676,
    name: "t-tenantSlug-reset-password___pt"
  }, {
    path: "/t/:tenantSlug?/sign-up",
    component: _4b8ddb9c,
    name: "t-tenantSlug-sign-up___pt"
  }, {
    path: "/t/:tenantSlug?/studio",
    component: _3a40e7c5,
    name: "t-tenantSlug-studio___pt"
  }, {
    path: "/t/:tenantSlug?/m/account-configuration",
    component: _144a1a10,
    name: "t-tenantSlug-m-account-configuration___pt"
  }, {
    path: "/t/:tenantSlug?/studio/settings",
    component: _162c1e00,
    children: [{
      path: "",
      component: _582bb6fa,
      name: "t-tenantSlug-studio-settings___pt"
    }, {
      path: "app-builder",
      component: _01e36e3a,
      name: "t-tenantSlug-studio-settings-app-builder___pt"
    }, {
      path: "integrations",
      component: _2eecfffe,
      name: "t-tenantSlug-studio-settings-integrations___pt"
    }, {
      path: "internal",
      component: _22326f9f,
      name: "t-tenantSlug-studio-settings-internal___pt"
    }, {
      path: "system-entities",
      component: _24228bda,
      name: "t-tenantSlug-studio-settings-system-entities___pt"
    }, {
      path: "tokens",
      component: _0094d3fc,
      name: "t-tenantSlug-studio-settings-tokens___pt"
    }, {
      path: "user-groups",
      component: _5c4bf64a,
      name: "t-tenantSlug-studio-settings-user-groups___pt"
    }, {
      path: "users",
      component: _4c0ad67c,
      name: "t-tenantSlug-studio-settings-users___pt"
    }, {
      path: "user-groups/new",
      component: _69ccd818,
      name: "t-tenantSlug-studio-settings-user-groups-new___pt"
    }, {
      path: "integrations/:id",
      component: _766ce2ae,
      name: "t-tenantSlug-studio-settings-integrations-id___pt"
    }, {
      path: "internal/:dummy-data",
      component: _727fce9e,
      name: "t-tenantSlug-studio-settings-internal-dummy-data___pt"
    }, {
      path: "tokens/:api-tokens-card",
      component: _1299647a,
      name: "t-tenantSlug-studio-settings-tokens-api-tokens-card___pt"
    }, {
      path: "user-groups/:id?",
      component: _56170332,
      name: "t-tenantSlug-studio-settings-user-groups-id___pt"
    }, {
      path: "user-groups/:user-group-form",
      component: _6feb3150,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___pt"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _06e360a8,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___pt"
    }, {
      path: "users/:active-members-card",
      component: _4d03c335,
      name: "t-tenantSlug-studio-settings-users-active-members-card___pt"
    }, {
      path: "users/:users-data-table-card",
      component: _8820a792,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/automations/:id?",
    component: _3c98d3cc,
    children: [{
      path: "",
      component: _2d4cbdc6,
      name: "t-tenantSlug-studio-automations-id___pt"
    }, {
      path: "history",
      component: _055c69c2,
      name: "t-tenantSlug-studio-automations-id-history___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/data/:id?",
    component: _a39dda9c,
    name: "t-tenantSlug-studio-data-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/pages/:id?",
    component: _69f57fe2,
    name: "t-tenantSlug-studio-pages-id___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId",
    component: _5d329824,
    name: "t-tenantSlug-m-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId",
    component: _739c8532,
    name: "t-tenantSlug-p-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/new",
    component: _1420ad55,
    name: "t-tenantSlug-m-viewId-r-new___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _dc41fcba,
    name: "t-tenantSlug-m-viewId-r-recordId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _5ecd4b34,
    name: "t-tenantSlug-p-viewId-r-recordId___pt"
  }, {
    path: "/:locale(en)?/builder/:after(.*)",
    redirect: (to) => ({ path: `${to.params.locale}/studio/${to.params.after}` })
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
