<i18n lang="yaml">
pt:
  empty: 'Nenhum resultado encontrado'
  views: 'Páginas'
  sheets: 'Tabelas'
  workflows: 'Automações'
en:
  empty: 'No matching results'
  views: 'Pages'
  sheets: 'Sheets'
  workflows: 'Workflows'
</i18n>

<template>
  <div
    v-if="!emptyResult"
  >
    <SearchableItemsList
      v-for="(items, type) in validResult"
      :key="type"
      :subheader="$t(type) || null"
      :items="items"
      :truncate-at="3"
      @item-clicked="$emit('item-clicked')"
    />
  </div>
  <v-list v-else dense>
    <v-subheader>
      {{ $t('empty') }}
    </v-subheader>
  </v-list>
</template>

<script>
import SearchableItemsList from '../_searchable-items-list';

export default {
  name: 'TheTopbarSearchResult',
  components: {
    SearchableItemsList,
  },
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    validResult() {
      const emptyResultKeys = Object.keys(this.result).filter(this.emptyResultForKey);
      return this._.omit(this.result, emptyResultKeys);
    },
    emptyResult() {
      return this._.isEmpty(this.validResult);
    },
  },
  methods: {
    emptyResultForKey(key) {
      return this._.isEmpty(this.result) || this._.isEmpty(this.result[key]);
    },
  },
};
</script>

