import { groupBy, flattenDeep } from 'lodash';
import { getFieldDisplayName } from '~/assets/javascript/utils';

export default class RecordList {
  constructor(view, draftRecords, options, emptyLabel, filterByValue) {
    this.view = view;
    this.draftRecords = draftRecords;
    this.emptyLabel = emptyLabel;
    this.options = options;
    this.filterByValue = filterByValue;
  }

  get records() {
    const records = this.#filterRecords(this.draftRecords);

    return this.#groupRecords(records);
  }

  #filterRecords(items) {
    if (!this.options.hasFilterBy || !this.filterByValue) return items;

    const filterEmpty = this.filterByValue === this.emptyLabel;

    return items.filter((record) => {
      const fieldData = record[this.options.filterByFieldId];

      if (!fieldData) return filterEmpty;

      const displayName = getFieldDisplayName(
        fieldData,
        this.options.fieldIdMapping[this.options.filterByFieldId],
      );

      if (Array.isArray(displayName)) {
        if (displayName.length === 0) return filterEmpty;

        return flattenDeep(displayName).includes(this.filterByValue);
      }

      return displayName === this.filterByValue;
    });
  }

  #groupRecords(items) {
    if (!this.options.hasGroup || this.view.page_type !== 'Kanban') return items;

    return groupBy(items, (record) => {
      const fieldData = record[this.options.groupFieldId];

      if (!fieldData) return this.emptyLabel;

      const displayName = getFieldDisplayName(
        fieldData,
        this.options.fieldIdMapping[this.options.groupFieldId],
      );

      if (Array.isArray(displayName) && displayName.length === 0) {
        return this.emptyLabel;
      }

      return displayName;
    });
  }
}
