import { version as uuidVersion, validate as uuidValidate } from 'uuid';

export const generateUuid = () => {
  try {
    try {
      // get crypto from DOM
      return crypto.randomUUID();
    } catch {
      // get crypto from nodejs
      return require('crypto').randomUUID();
    }
  } catch {
    // when there is no Crypto module available
    const alphabetSize = 26; // how many uniq letters there are in a single letter digit
    const numericSize = 10; // how many uniq numbers there are in a single numeric digit

    return Math.random().toString(alphabetSize + numericSize).substring(2);
  }
};

export const isUuidV4 = uuid => uuidValidate(uuid) && uuidVersion(uuid) === 4;

export const regex = /([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/g;
