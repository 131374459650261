import { archives } from './archives';
import { executions } from './executions';

export const workflows = $axios => ({
  create: (data = {}) => $axios.$post('/workflows', data),
  get: (workflowId, params = {}) => $axios.$get(`/workflows/${workflowId}`, { params }),
  list: (params = {}) => $axios.$get('/workflows', { params }),
  update: (workflowId, params) => $axios.$patch(`/workflows/${workflowId}`, params),
  // nested resources
  archives: archives($axios),
  executions: executions($axios),
});
