export default ({ $rollbar, store }, _inject) => {
  $rollbar.configure({
    transform: (payload) => {
      payload.workspace_id = store?.state?.workspace?.workspace?.id;
      payload.workspace_slug = store?.state?.workspace?.workspace?.slug;

      if (window.FS && window.FS.getCurrentSessionURL) {
        payload.fullstorysessionURL = window.FS.getCurrentSessionURL(true);
      }
    },
  });
};
