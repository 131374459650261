<template>
  <div class="switch-menu">
    <v-menu
      offset-y
      attach
      left
      :disabled="disabled"
    >
      <template #activator="{ on }">
        <v-chip
          outlined
          v-on="on"
        >
          <v-icon
            size="18"
            color="grey darken-1"
            class="mr-2"
          >
            {{ selectedItem?.icon }}
          </v-icon>

          <span class="text-truncate hidden-sm-and-down">
            {{ selectedItem?.title }}
          </span>

          <v-icon
            v-if="!disabled"
            size="14"
            color="grey darken-1"
            class="ml-1"
          >
            fa-regular fa-chevron-down
          </v-icon>
        </v-chip>
      </template>

      <v-list subheader dense>
        <v-subheader class="pl-4">
          {{ subheader }}
        </v-subheader>
        <template v-for="item in items">
          <v-divider
            v-if="item.divider"
            :key="`${item.key}-divider`"
            class="mb-2"
          />

          <v-list-item
            :key="item.key"
            :aria-label="item.title"
            :to="item.to"
            v-on="item.key !== selectedItem?.key && item.on"
          >
            <v-icon
              size="18"
              class="mr-2"
            >
              {{ item.icon }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>

            <v-icon
              v-if="item.key === selectedItem?.key"
              size="14"
              color="grey darken-1"
              class="ml-2"
            >
              fa-regular fa-check
            </v-icon>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'TheTopbarSwitchMenu',
  props: {
    items: { type: Array, default: () => [], validator: value => value.every(item => item.key && item.title) },
    selectedItem: { type: Object, default: () => ({}) },
    subheader: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
};
</script>
