import { mapActions, mapGetters } from 'vuex';

export const workspace = (params = {}) => ({
  created() {
    this.workspaceMixin__loadWorkspaceData();
    this.workspaceMixin__identifyUserIfLoggedIn();
  },
  computed: {
    ...mapGetters('workspace', ['primaryColor', 'hasDummyData']),
  },
  watch: {
    primaryColor() {
      this.workspaceMixin__updatePrimaryColor();
    },
  },
  async mounted() {
    await this.$nextTick();
    this.workspaceMixin__updatePrimaryColor();

    if (this.$auth.user?.role === 'admin') {
      this.fetchFeatures();

      Object.keys(this.$route.query).filter(key => key.startsWith('feature_')).forEach((key) => {
        const flag = key.replace('feature_', '');
        const value = this.$route.query[key] === 'true';
        this.toggleFeature({ flag, value });
      });
    }
  },
  methods: {
    ...mapActions('workspace', ['loadWorkspace']),
    ...mapActions('features', ['fetchFeatures', 'toggleFeature']),
    workspaceMixin__updatePrimaryColor() {
      this.$vuetify.theme.themes.light.primary = this.primaryColor;
    },
    async workspaceMixin__identifyUserIfLoggedIn() {
      if (!this.$auth.user) return;

      this.$segmentIdentifyUser();
    },
    async workspaceMixin__loadWorkspaceData() {
      if (!this.$auth.user && !params.isPublic) return;

      if (params.isPublic) {
        params.tenantSlug = this.$route.params?.tenantSlug;
      }

      try {
        await this.loadWorkspace(params);
      } catch (error) {
        this.$rollbar.error('Workspace load', error);
      }
    },
  },
});
