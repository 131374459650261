import { omit, pickBy, isEmpty } from 'lodash';
import Vue from 'vue';
import { enrichWorkflowData } from '~/assets/javascript/utils';
import { formatWorkflow } from '~/assets/javascript/utils/workflow';

export const state = () => ({
  loadingWorkflows: false,
  workflows: [],
  workflow: {},
  stepIdLoading: null,
  stepErrors: {},
  stepsVisibility: {},
});

export const getters = {
  currentWorkflow: state => state.workflow,
  currentTrigger: (_, { currentWorkflow }) => currentWorkflow.trigger || {},
  currentSheet: (_, { currentTrigger }, rootState) => {
    const sheets = rootState['viewOptions/sheetsCache'] || [];
    if (currentTrigger.sheet_id) return sheets.find(({ id }) => id === currentTrigger.sheet_id);

    return sheets.find(
      sheet => sheet.views.find(({ id }) => id === currentTrigger.view_id),
    );
  },
  currentView: (_, { currentTrigger }) => currentTrigger.view,
  availableFields: (_, { currentTrigger }) => currentTrigger?.fields || [],
  workflowStepsMap: (_, { currentWorkflow }) => {
    const stepsMap = {};

    const collectSteps = (steps) => {
      steps.forEach((step) => {
        stepsMap[step.id] = step;

        if (step.sorted_steps) {
          collectSteps(step.sorted_steps);
        }
      });
    };

    if (currentWorkflow.sorted_steps) {
      collectSteps(currentWorkflow.sorted_steps);
    }

    return stepsMap;
  },
  isArchived: ({ workflow }) => Boolean(workflow.archived_at),
};

export const mutations = {
  assign(state, payload) {
    Object.assign(state, payload);
  },
  setWorkflows(state, workflows) {
    state.workflows = workflows;
  },
  setCurrentWorkflow(state, workflow) {
    state.workflow = formatWorkflow(workflow, globalThis.$nuxt?.$i18n?.locale);

    Vue.set(state, 'stepErrors', {});
  },
  setStepsVisibility(state, visible) {
    if (visible) {
      const visibility = Object.entries(state.workflow.stepsById).reduce((acc, [id]) => {
        acc[id] = true;
        return acc;
      }, {});
      state.stepsVisibility = visibility;
    } else {
      state.stepsVisibility = {};
    }
  },
  setStepVisibility(state, { stepId, visible }) {
    if (visible) {
      Vue.set(state.stepsVisibility, stepId, visible);
    } else {
      Vue.delete(state.stepsVisibility, stepId);
    }
  },
  setLoadingStepId(state, id) {
    state.stepIdLoading = id;
  },
  setStepErrors(state, { stepId, errors }) {
    Vue.set(state.stepErrors, stepId, errors);
  },
  updateLocalStepMapping(state, { workflowId, stepId, key, value, mapping }) {
    if (workflowId !== state.workflow.id) return;

    const step = state.workflow.stepsById[stepId];

    if (mapping) {
      Vue.set(step, 'mapping', mapping);
    } else {
      Vue.set(step.mapping, key, value);
    }
  },
};

export const actions = {
  async reloadCurrentWorkflow({ dispatch, getters }) {
    if (!getters.currentWorkflow.id) return;
    await dispatch('loadCurrentWorkflow', getters.currentWorkflow.id);
  },
  async loadCurrentWorkflow({ commit }, workflowId = {}) {
    if (workflowId) {
      const workflowData = await this.$apiClient.workflows.get(workflowId);
      commit('setCurrentWorkflow', workflowData);
    } else {
      commit('setCurrentWorkflow', {});
    }
  },
  async loadWorkflows({ commit, rootGetters, state }) {
    if (state.loadingWorkflows) return;

    commit('assign', { loadingWorkflows: true, stepIdLoading: null });

    try {
      const workflows = await this.$apiClient.workflows.list();

      const categories = rootGetters['workspace/categoryHierarchy'];
      const enrichedWorkflows = enrichWorkflowData(workflows, categories);
      commit('assign', { workflows: enrichedWorkflows });
    } catch (error) {
      this.$errorRescue(this, error, 'loadWorkflows');
    } finally {
      commit('assign', { loadingWorkflows: false });
    }
  },
  async updateStep({ dispatch, commit }, { stepId, payload }) {
    try {
      commit('setLoadingStepId', stepId);
      commit('setStepErrors', { stepId, errors: [] });
      await this.$api.$patch(`/workflow_steps/${stepId}`, payload);
      commit('setLoadingStepId', null);
    } catch (e) {
      const errors = e.response?.data?.errors;

      if (errors) commit('setStepErrors', { stepId, errors });

      commit('setLoadingStepId', null);
      throw e;
    }

    await dispatch('reloadCurrentWorkflow');
  },
  async updateStepMapping({ state, commit, dispatch }, { stepId, payload }) {
    const step = state.workflow.stepsById[stepId];
    const mapping = payload;

    Object.keys(mapping).forEach((mappingKey) => {
      const schema = step.action_schema[mappingKey];

      if (isEmpty(mapping[mappingKey])) {
        delete mapping[mappingKey];
      }

      if (schema.display_if) {
        const { key, accessor, value } = schema.display_if;
        const mappingItem = mapping[key] || {};

        if (
          (accessor === 'value' && mappingItem.value !== value)
          || (accessor === 'type' && mappingItem.type !== value)
        ) {
          delete mapping[mappingKey];
        }
      }
    });

    commit('updateLocalStepMapping', { workflowId: step.workflow_id, stepId, mapping });

    return dispatch('updateStep', { stepId, payload: { mapping: payload } });
  },
  removeStepMapping({ state, dispatch }, { stepId, keys }) {
    const step = state.workflow.stepsById[stepId];

    const newMapping = omit(step.mapping, keys);

    return dispatch('updateStepMapping', { stepId, payload: newMapping });
  },
  incrementStepMapping({ state, dispatch }, { stepId, payload }) {
    const step = state.workflow.stepsById[stepId];

    const newMapping = pickBy(
      {
        ...step.mapping,
        ...payload,
      },
      (_, key) => key in step.action_schema,
    );

    return dispatch('updateStepMapping', {
      stepId,
      payload: newMapping,
    });
  },
  async createWorkflow({ commit }, { name, triggerType, appCategoryName }) {
    const workflow = await this.$apiClient.workflows.create({
      name,
      trigger_type: triggerType,
      app_category_name: appCategoryName,
    });

    commit('setWorkflows', [...(state.workflows || []), workflow]);

    return workflow;
  },
  async removeWorkflow({ commit, state }, { workflowId }) {
    await this.$api.delete(`/workflows/${workflowId}`);

    if (state.workflow.id === workflowId) {
      commit('setCurrentWorkflow', {});
    }

    commit('assign', { workflows: [...state.workflows.filter(({ id }) => id !== workflowId)] });
  },
  async updateWorkflow({ commit, state: { workflows, workflow } }, { workflowId, ...workflowData }) {
    await this.$apiClient.workflows.update(workflowId, workflowData);
    const updatedWorkflow = { ...workflow, ...workflowData };
    commit('setCurrentWorkflow', updatedWorkflow);

    const updatedWorkflows = workflows.map((workflow) => {
      if (workflow.id === workflowId) {
        return { ...updatedWorkflow };
      }

      return workflow;
    });

    commit('assign', { workflows: [...updatedWorkflows] });
  },
  async updateTrigger({ dispatch }, { viewId, sheetId, workflowTriggerId, paramsMap, sync }) {
    try {
      const updateTriggerTrackPayloadMap = {
        view: { newState: viewId, property: 'view_id' },
        sheet: { newState: sheetId, property: 'sheet_id' },
        paramsMap: { property: 'params_map' },
        sync: { newState: sync, property: 'sync' },
        default: {},
      };
      const key = (viewId && 'view')
        || (sheetId && 'sheet')
        || (paramsMap && 'params_map')
        || (sync && 'sync')
        || 'default';

      window.analytics.track('updateTrigger', updateTriggerTrackPayloadMap[key]);

      const trigger = await this.$apiClient.workflowTriggers.update(
        workflowTriggerId,
        { view_id: viewId, sheet_id: sheetId, params_map: paramsMap, sync },
      );

      await dispatch('reloadCurrentWorkflow');
      return trigger;
    } catch (error) {
      this.$errorRescue(this, error, 'updateTrigger');

      return null;
    }
  },
  async removeWorkflowStep({ dispatch, commit }, stepId) {
    commit('assign', { loadingWorkflows: true });

    try {
      await this.$api.delete(`/workflow_steps/${stepId}`);
      await dispatch('reloadCurrentWorkflow');
    } catch (err) {
      commit('assign', { loadingWorkflows: false });
      throw err;
    }
  },
};
