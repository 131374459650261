import Vue from 'vue';
import { cloneDeep, omit, pick, isEmpty } from 'lodash';
import {
  getSelfLinkFields,
  formatRecords,
  formatRecordToImport,
  validateAndFilterRecordForImport,
  OMITTED_COLUMNS,
  UPDATE_FORMAT,
  STEPS,
} from '~/assets/javascript/store-helpers/import';

export const state = () => ({
  step: STEPS.initial,
  importedRecords: [],
  sheetId: null,
  users: [],
  sheets: [],
  matchedColumns: {},
  rawImportedRecords: [],
  removedColumns: [],
  separator: null,
});

export const cacheableStateKeys = [
  'step',
  'importedRecords',
  'sheetId',
  'matchedColumns',
  'removedColumns',
  'rawImportedRecords',
];

export const getters = {
  sheet: ({ sheets, sheetId }) => sheets.find(sheet => sheet.id === sheetId),
  view: (_, { sheet }) => sheet?.view,
  records: ({ importedRecords, matchedColumns, users, sheets, rawImportedRecords, separator }) => (sheets.length > 0 ? formatRecords(
    cloneDeep(importedRecords),
    matchedColumns,
    users,
    sheets,
    rawImportedRecords,
    separator,
  ) : []),
  importedRecordsCount: (_, { records }) => records.filter(r => r.state === 'success').length,
  fields: ({ matchedColumns, removedColumns }, { records }) => (overrideAttributes) => {
    if (!records) return [];

    const columns = Object.keys(omit(records[0], OMITTED_COLUMNS));

    return columns.map((key) => {
      const field = matchedColumns[key] || {};
      const removed = removedColumns.includes(key);
      const customAttributes = {};
      let label = key;

      if (field.name) customAttributes.labelClass = overrideAttributes.matchedLabelClass;
      else if (removed) customAttributes.labelClass = overrideAttributes.removedLabelClass;
      else customAttributes.labelClass = overrideAttributes.unmatchedLabelClass;

      if (removed) {
        label = overrideAttributes.removedLabel;
      } else if (!field.name) {
        label = overrideAttributes.unmatchedLabel;
      }

      return {
        label,
        customAttributes,
        removed,
        id: key,
        type: field.type || 'String',
        name: field.name || key,
        system_name: field.system_name,
        options: { ...field.options },
        rules: field.rules,
      };
    });
  },
  finished: (_, { records, importedRecordsCount }) => records.length > 0 && records.length === importedRecordsCount,
  started: (_, { importedRecordsCount }) => importedRecordsCount > 0,
  importingRecordsLinkOptions: ({ rawImportedRecords }, { sheet }) => {
    if (!sheet || !rawImportedRecords) return {};
    const primaryFieldId = sheet.primary_field_id || sheet.view.fields[0].id;
    const primaryField = sheet.view.fields.find(({ id }) => id === primaryFieldId);

    return {
      [sheet.id]: rawImportedRecords.map(record => ({ text: record[primaryField.name], value: record.id })),
    };
  },
};

export const mutations = {
  setStep(state, step) {
    state.step = step;
  },
  setSheetId(state, sheetId) {
    state.sheetId = sheetId;
  },
  setImportedRecords(state, records) {
    state.importedRecords = records.map((record, index) => {
      record.id = index.toString();
      return record;
    });

    state.rawImportedRecords = cloneDeep(state.importedRecords);
  },
  setMatchedColumn(state, { fieldId, field }) {
    Vue.set(state.matchedColumns, fieldId, field);
  },
  setRecordState(state, { recordIndex, recordState }) {
    const row = state.importedRecords[recordIndex];
    row.state = recordState;
    state.importedRecords.splice(recordIndex, 1, row);
  },
  removeMatchedColumn(state, fieldId) {
    Vue.delete(state.matchedColumns, fieldId);
    state.removedColumns.push(fieldId);
  },
  enableColumn(state, fieldId) {
    state.removedColumns.splice(state.removedColumns.indexOf(fieldId), 1);
  },
  updateImportedRecordData(state, { recordId, fieldId, value }) {
    const field = state.matchedColumns[fieldId];

    const data = state.importedRecords[recordId];
    data[fieldId] = value;

    if (field && UPDATE_FORMAT[field.type]) {
      UPDATE_FORMAT[field.type]({ record: data, field, fieldId });
    }
  },
  setUsers(state, users) {
    state.users = users;
  },
  setSheets(state, sheets) {
    state.sheets = sheets;
  },
  reset(state) {
    state.step = STEPS.initial;
    state.importedRecords = [];
    state.sheetId = null;
    state.matchedColumns = {};
    state.removedColumns = [];
    state.users = [];
  },
  setSeparator(state, separator) {
    state.separator = separator;
  },
};

export const actions = {
  restartImport({ commit }) {
    commit('reset');
  },
  async setStep({ commit, dispatch }, step) {
    await commit('setStep', step);

    if (step === STEPS.manipulation) {
      await dispatch('loadUsers');
    }
  },
  async loadSheets({ commit }) {
    const sheets = await this.$api.$get('/sheets');

    await Promise.all(sheets.map(async (sheet) => {
      const defaultView = sheet.views.find(view => view.page_data_default);
      sheet.view = await this.$api.$get(`/builder/views/${defaultView.id}`);
    }));

    return commit('setSheets', sheets);
  },
  async loadUsers({ commit }) {
    const users = await this.$api.$get('/users');
    return commit('setUsers', users);
  },
  async importFormattedRecords({ state, commit, dispatch, getters: { records } }, selected) {
    const recordsToImport = records.filter(validateAndFilterRecordForImport(state, selected, commit));
    const selfLinkFields = getSelfLinkFields(Object.values(state.matchedColumns), state.sheets);
    const selfLinkFieldIds = selfLinkFields.map(field => field.id);
    const recordsToImportIdsMapping = {};
    const selfLinkFieldsData = {};
    const recordsIdsNotImported = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const record of recordsToImport) {
      let newRecord;
      let recordIndex;

      try {
        const { fields, user } = formatRecordToImport(record, state.matchedColumns, state.removedColumns);
        recordIndex = parseInt(record.id, 10);
        selfLinkFieldsData[recordIndex] = pick(fields, selfLinkFieldIds);

        const body = {
          values: omit(fields, selfLinkFieldIds),
          sheet_id: state.sheetId,
        };

        if (user?.id) {
          body.user_id = user.id;
        }

        // eslint-disable-next-line no-await-in-loop
        newRecord = await this.$api.$post('/records', body);
        recordsToImportIdsMapping[recordIndex] = newRecord.id;

        if (isEmpty(selfLinkFieldsData[recordIndex])) {
          commit('setRecordState', { recordIndex, recordState: 'success' });
        }
      } catch (e) {
        if (newRecord?.id) {
          // eslint-disable-next-line no-await-in-loop
          await this.$api.delete(`/records/${newRecord.id}`);
          delete recordsToImportIdsMapping[recordIndex];
          delete selfLinkFieldsData[recordIndex];
        }

        recordsIdsNotImported.push(recordIndex);

        this.$rollbar.error('importFormattedRecords', e, e.response);
        commit('setRecordState', { recordIndex, recordState: 'error' });
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [recordIndex, selfLinkFieldsDataForRecord] of Object.entries(selfLinkFieldsData)) {
      const recordId = recordsToImportIdsMapping[recordIndex];

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const [fieldId, values] of Object.entries(selfLinkFieldsDataForRecord)) {
          // eslint-disable-next-line no-restricted-syntax
          for (const value of values.filter(v => !recordsIdsNotImported.includes(v))) {
            const params = {
              foreign_record_id: recordsToImportIdsMapping[value] || value,
              field_id: fieldId,
            };

            const path = `records/${recordsToImportIdsMapping[recordIndex]}/link`;
            // eslint-disable-next-line no-await-in-loop
            await this.$api.post(path, params);
          }
        }

        commit('setRecordState', { recordIndex, recordState: 'success' });
      } catch (e) {
        if (recordId) {
          // eslint-disable-next-line no-await-in-loop
          await this.$api.delete(`/records/${recordId}`);
          delete recordsToImportIdsMapping[recordIndex];
          delete selfLinkFieldsData[recordIndex];
        }

        recordsIdsNotImported.push(recordIndex);

        this.$rollbar.error('importFormattedRecords', e, e.response);
        commit('setRecordState', { recordIndex, recordState: 'error' });
      }
    }

    await dispatch('loadUsers');
  },
};
