import { isEmpty, isObject } from 'lodash';
import { isUuidV4, regex as uuidRegex } from '~/assets/javascript/utils/string/uuid';

const ATTRIBUTES_WITH_FIELD_INFO_COMPONENT = [
  'title',
  'subtitle',
  'profile_image',
  'cover_image',
  'avatar_image',
  'footer_content',
  'allow_filter_by',
  'column_group_by',
  'row_group_by',
  'start_date',
  'end_date',
  'color',
];

const COMPONENT_TYPES = [
  'field',
  'chart',
  'group',
];

const fieldIdsUsedInChart = (content) => {
  const matches = content.matchAll(uuidRegex);
  const chartFieldsIds = new Set();

  Array.from(matches).forEach((match) => {
    chartFieldsIds.add(match[1]);
  });

  // Return only valid UUIDs
  return chartFieldsIds.filter(fieldId => fieldId.match(/^[A-Za-z0-9-]{36}$/));
};

export const fetchMainContentInfoComponentById = (view, componentId) => {
  const pageStateComponents = view.page_state_components;
  const infoComponent = Object.values(pageStateComponents)
    .map(pageState => pageState.main_content)
    .flat()
    .find(component => component.id === componentId);

  return infoComponent || null;
};

export const fetchFieldsIdsFromInfoComponent = (infoComponent, allowedTypes = COMPONENT_TYPES) => {
  if (!allowedTypes.includes(infoComponent.type)) return [];

  const fieldsIds = new Set();

  if (infoComponent.type === 'field') {
    fieldsIds.add(infoComponent.field_id);
  }

  if (infoComponent.type === 'chart') {
    const chartFieldsIds = fieldIdsUsedInChart(infoComponent.content);

    chartFieldsIds.forEach((id) => {
      fieldsIds.add(id);
    });
  }

  if (infoComponent.type === 'group' && !isEmpty(infoComponent.items)) {
    infoComponent.items.forEach((item) => {
      const fieldIdsFromGroup = fetchFieldsIdsFromInfoComponent(item, allowedTypes);

      fieldIdsFromGroup.forEach((id) => {
        fieldsIds.add(id);
      });
    });
  }

  return Array.from(fieldsIds);
};

export const fetchFieldsIdsFromPageStateComponent = (pageStateComponents = {}) => {
  let fieldsIds = new Set();

  if (!pageStateComponents) { return []; }

  Object.values(pageStateComponents).forEach((state) => {
    if (!state) { return; }

    Object.keys(state).forEach((sectionKey) => {
      const component = state[sectionKey];

      ATTRIBUTES_WITH_FIELD_INFO_COMPONENT.forEach((attributeKey) => {
        const value = component[attributeKey];
        let fieldId;

        if (isObject(value)) {
          fieldId = component[attributeKey]?.field_id;
        } else if (isUuidV4(value)) {
          fieldId = value;
        }

        if (fieldId) { fieldsIds.add(fieldId); }
      });

      if (component.main_content) {
        component.main_content.forEach((infoComponent) => {
          const fieldIdsFromInfoComponent = fetchFieldsIdsFromInfoComponent(infoComponent);
          fieldsIds = new Set([...fieldsIds, ...fieldIdsFromInfoComponent]);
        });
      }
    });
  });

  return Array.from(fieldsIds);
};

export * from './info-components';
