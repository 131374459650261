import { deburr, kebabCase } from 'lodash';

export const slugify = content => kebabCase(deburr(content?.replace(/&nbsp;/g, '')));

export const normalize = content => deburr(content?.replace(/&nbsp;/g, ' ') || '').toLowerCase().replace(/\s/g, '');

export const parameterize = (string) => {
  const reDuplicateSeparator = /-{2,}/g;
  const reLeadingTrailingSeparator = /^-|-$/ig;
  const separator = '-';

  return deburr(string)
    // Turn unwanted chars into the separator.
    .replaceAll(/[^a-z0-9\-_]+/ig, separator)
    // # No more than one of the separator in a row.
    .replaceAll(reDuplicateSeparator, separator)
    // # Remove leading/trailing separator.
    .replaceAll(reLeadingTrailingSeparator, '')
    .toLowerCase();
};
