<i18n lang="yaml">
pt:
  data: 'Dados'
  home: 'Loja de Apps'
  settings: 'Ajustes'
  views: 'Páginas'
  workflows: 'Automações'
en:
  data: 'Data'
  home: 'App Store'
  settings: 'Settings'
  views: 'Pages'
  workflows: 'Automations'
</i18n>

<template>
  <v-chip-group show-arrows class="studio-topbar__pages-menu">
    <v-tooltip v-for="(item, i) in items" :key="i" bottom>
      <template #activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          outlined
          :to="item.to"
          :exact="item.exact"
          class="lighten-5 pa-2"
          v-on="on"
        >
          <v-icon small>
            {{ item.icon }}
          </v-icon>
        </v-chip>
      </template>
      <span>{{ item.title }}</span>
    </v-tooltip>
  </v-chip-group>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StudioTopbarPagesMenu',
  props: {
    transparent: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('workspace', ['tenantSlug']),
    builderItems() {
      return [
        {
          icon: 'fa-database fa-regular',
          title: this.$t('data'),
          to: this.localePath({ name: 't-tenantSlug-studio-data-id', params: { tenantSlug: this.tenantSlug } }),
        },
        {
          icon: 'fa-browser fa-regular',
          title: this.$t('views'),
          to: this.localePath({ name: 't-tenantSlug-studio-pages-id', params: { tenantSlug: this.tenantSlug } }),
        },
        {
          icon: 'fa-bolt fa-regular',
          title: this.$t('workflows'),
          to: this.localePath({ name: 't-tenantSlug-studio-automations-id', params: { tenantSlug: this.tenantSlug } }),
        },
        {
          icon: 'fa-gear fa-regular',
          title: this.$t('settings'),
          to: this.localePath({ name: 't-tenantSlug-studio-settings', params: { tenantSlug: this.tenantSlug } }),
        },
      ];
    },
    items() {
      return this.$auth.loggedIn && this.$auth.user.role === 'admin' ? this.builderItems : [];
    },
  },
};
</script>

<style lang="scss">
.studio-topbar__pages-menu .v-slide-group__content {
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
