function sanitizeString(valueAsString, separator) {
  return valueAsString.replace(new RegExp(`[^0-9${separator}]`, 'g'), '').replace(separator, '.');
}

export function precisionPow(precision) {
  return Math.round(10 ** precision);
}

export function floatifyNumber(valueAsInteger, precision) {
  return valueAsInteger / precisionPow(precision);
}

export function integerifyNumber(valueAsFloat, precision) {
  return Math.round(valueAsFloat * precisionPow(precision));
}

export function integerifyNumberFromString(valueAsString, separator, precision) {
  const floatValue = parseFloat(sanitizeString(valueAsString, separator));
  return integerifyNumber(floatValue, precision);
}

export function formatWithThousandSeparator(valueAsString, thousandSeparator) {
  return valueAsString.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
}
