const ROUTES = {
  studio: {
    view: {
      routeName: 't-tenantSlug-studio-pages-id',
      viewId: 'id',
    },
    sheets: {
      routeName: 't-tenantSlug-studio-data-id',
      sheetId: 'id',
    },
    workflows: {
      routeName: 't-tenantSlug-studio-automations-id',
      workflowId: 'id',
    },
  },
  member: {
    views: {
      routeName: 't-tenantSlug-m',
    },
    view: {
      routeName: 't-tenantSlug-m-viewId',
      viewId: 'viewId',
      record: {
        routeName: 't-tenantSlug-m-viewId-r-recordId',
        viewId: 'viewId',
        new: {
          routeName: 't-tenantSlug-m-viewId-r-new',
          viewId: 'viewId',
        },
      },
    },
  },
  public: {
    view: {
      routeName: 't-tenantSlug-p-viewId',
      viewId: 'viewId',
      record: {
        routeName: 't-tenantSlug-p-viewId-r-recordId',
        viewId: 'viewId',
      },
    },
    emailValidation: {
      routeName: 't-tenantSlug-email-validation',
    },
    login: {
      routeName: 't-tenantSlug-login',
    },
  },
};

export default ROUTES;

export const SHOW_RECORDS_ROUTES = [
  ROUTES.member.view.record,
  ROUTES.public.view.record,
];

export const NEW_RECORDS_ROUTES = [
  ROUTES.member.view.record.new,
];
