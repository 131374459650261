import { undefinedTranslate } from '~/mixins/dataMixin';
import { STEP_TYPES } from '~/assets/javascript/constants/types';

// Helper function to search for the workflow recursively
const findWorkflowPath = (workflow, targetId, path = []) => {
  // Base case

  if (workflow.id === targetId) {
    return path;
  }

  // If the current workflow is a group, recursively search for the target workflow in its items
  if (workflow.items) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of workflow.items) {
      const result = findWorkflowPath(item, targetId, [...path, workflow.group_name]);

      if (result) return result;
    }
  }

  return null;
};

export const groupsPathToWorkflow = (workflows, workflowId) => {
  if (!workflows || !workflowId) return null;
  // workflows must be like category.workflows, not a list of workflows.

  // If a workflow is inside Finance category and under the Budget group, under My Stuff group, the path will be ['Budget', 'My Stuff']

  // Iterate through each workflow and call the helper function to find the path
  // eslint-disable-next-line no-restricted-syntax
  for (const workflow of workflows) {
    const path = findWorkflowPath(workflow, workflowId);

    if (path) return path;
  }

  // Workflow not found
  return null;
};

export const enrichWorkflowData = (workflows, categories) => {
  if (categories.length === 0) return workflows;

  const enrichedWorkflowData = [];
  const workflowsEnriched = {};

  categories.forEach((category) => {
    [...workflows.filter(v => !workflowsEnriched[v.id])].forEach((workflow) => {
      const path = groupsPathToWorkflow(category.workflows, workflow.id); // tests all categories until it finds a valid path to the workflow

      if (path) {
        enrichedWorkflowData.push({
          ...workflow,
          groupsPath: path,
          category,
        });

        workflowsEnriched[workflow.id] = true;
      }
    });
  });

  return enrichedWorkflowData;
};

export const formatSteps = (sortedSteps, locale = 'en') => {
  const stepsById = {};

  const formatStep = (step, index, prefix = '') => {
    let displayIndex;

    if (prefix === '') {
      displayIndex = `${index}`;
    } else {
      displayIndex = `${prefix}.${index}`;
    }

    step.index = index;
    step.displayIndex = displayIndex;
    step.displayIcon = step.display_config?.icon || STEP_TYPES[step.action_type]?.icon || 'play';
    step.displayIconKind = step.display_config?.iconKind || STEP_TYPES[step.action_type]?.iconKind || 'regular';
    step.displayName = step.name || step.display_config?.name?.[locale] || STEP_TYPES[step.action_type]?.locales[locale] || undefinedTranslate[locale];

    if (step.sorted_steps) {
      step.sorted_steps.forEach((subStep, subIndex) => {
        formatStep(subStep, subIndex + 1, displayIndex);
      });
    }

    stepsById[step.id] = step;
  };

  sortedSteps.forEach((step, index) => {
    formatStep(step, index + 1);
  });

  return { sortedSteps, stepsById };
};

export const formatWorkflow = (workflow, locale) => {
  const { sortedSteps, stepsById } = formatSteps(workflow.sorted_steps || [], locale);
  workflow.sorted_steps = sortedSteps;
  workflow.stepsById = stepsById;

  return workflow;
};
