import { VIEW_TYPES } from '~/assets/javascript/constants';
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash';
import { fetchFieldsIdsFromPageStateComponent } from '~/assets/javascript/utils/page-state-components';
import { enrichViewData, extractViewRecords } from '~/assets/javascript/utils';
import Vue from 'vue';

export const state = () => ({
  view: {},
  views: [],
  editingView: {},
  permissions: [],
  selectedComponentKey: 'general',
  locale: 'en',
});

const NON_NULLABLE_STATE_KEYS = [
  'cover_image',
  'profile_image',
  'subtitle',
  'title',
];

const refreshFieldIds = (editingView) => {
  editingView.fields_ids = fetchFieldsIdsFromPageStateComponent(editingView.page_state_components);
};

const cleanUpPageStateComponents = (editingView) => {
  if (!editingView.page_state_components) { return; }

  Object.keys(editingView.page_state_components).forEach((key) => {
    Object.values(editingView.page_state_components[key]).forEach((pageStateComponent) => {
      if (!pageStateComponent) { return; }

      NON_NULLABLE_STATE_KEYS.forEach((nullableKey) => {
        if (pageStateComponent[nullableKey] === null) {
          delete pageStateComponent[nullableKey];
        }
      });
    });
  });
};

const loadSubviews = (view, subviews = []) => {
  (view.subviews || []).forEach((subview) => {
    subviews.push(subview);
    loadSubviews(subview, subviews);
  });

  return subviews;
};

export const getters = {
  everyonePermissions: ({ permissions }) => permissions.filter(({ everyone }) => everyone),
  sharedWithEveryone: (_, { everyonePermissions }) => Boolean(everyonePermissions.length),
  userGroupPermissions: ({ permissions }) => permissions.filter(({ grantee_type: granteeType }) => granteeType === 'UserGroup'),
  userPermissions: ({ permissions }) => permissions.filter(({ grantee_type: granteeType }) => granteeType === 'User'),
  editingViewState: ({ editingView, selectedComponentKey }) => {
    if (isEmpty(editingView)) return { component: {} };

    if (selectedComponentKey === 'general') {
      return {
        ...editingView,
        component: { key: 'general' },
      };
    }

    const selectedComponent = VIEW_TYPES[editingView.page_type].components.find(({ key }) => key === selectedComponentKey);

    if (selectedComponent) {
      return {
        ...editingView.page_state_components[selectedComponent.key],
        component: selectedComponent,
      };
    }

    return editingView;
  },
  liveEdit: (_, { layoutOptions }) => layoutOptions.liveEdit,
  viewFields: ({ editingView }) => editingView.fields,
  hasChanges: ({ view }) => view.has_changes,
  hasGroup(_state, { layoutOptions, groupFieldId }) {
    return !!(layoutOptions.canGroupDataByColumn && groupFieldId);
  },
  layoutOptions: ({ editingView }) => editingView.layoutOptions || {},
  groupFieldId: ({ editingView }) => editingView.page_state_components?.index?.main_section?.column_group_by,
  currentGroupByField: ({ locale }, { hasGroup, groupFieldId, viewFields }) => {
    if (hasGroup) {
      const fieldSpec = viewFields.find(({ id }) => id === groupFieldId);
      return { ...fieldSpec, i18nOptions: { locale } };
    }

    return null;
  },
  isPublic({ editingView }) {
    return Boolean(editingView.view_public);
  },
  findEditingSubview: (_, { subviews }) => (viewId, fieldId) => subviews.find(
    subview => subview.metadata.parent_view_id === viewId
      && subview.metadata.from_field_id === fieldId,
  ),
  subviews({ editingView }) {
    return loadSubviews(editingView);
  },
  unarchivedViews({ views }) {
    return views.filter(view => !view.archived_at);
  },
};

export const mutations = {
  setLocale(state, value) {
    state.locale = value;
  },
  setView(state, payload) {
    const layoutOptions = VIEW_TYPES[payload.page_type].builderOptions;

    if (isEmpty(state.view) || state.view.id !== payload.id) {
      state.view = payload;
      state.editingView = payload;
      state.editingView.layoutOptions = layoutOptions;
    } else {
      Object.keys(payload).forEach((key) => {
        if (isEqual(state.view[key], payload[key])) return;

        Vue.set(state.view, key, payload[key]);
        Vue.set(state.editingView, key, payload[key]);
      });

      if (state.view.page_type !== payload.page_type) {
        Vue.set(state.editingView, 'layoutOptions', layoutOptions);
      }
    }
  },
  updateViewData(state, payload) {
    const viewIsAlreadyStored = state.views.some(view => view.id === payload.id);

    if (viewIsAlreadyStored) {
      state.views = state.views.map((view) => {
        if (view.id === payload.id) {
          return payload;
        }

        return view;
      });
    } else {
      state.views = [...state.views, payload];
    }
  },
  setViews(state, payload) {
    state.views = payload;
  },
  storeNewView(state, payload) {
    state.views = [...state.views, payload];
  },
  setEditingView(state, payload) {
    state.editingView = payload;
    state.editingView.layoutOptions = VIEW_TYPES[payload.page_type].builderOptions;
    state.editingView.has_changes = true;
  },
  setEditingViewHasChanges(state) {
    state.editingView.has_changes = true;
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  resetEditingView(state) {
    state.editingView = state.view;
    state.editingView.layoutOptions = VIEW_TYPES[state.view.page_type].builderOptions;
  },
  setSelectedComponentKey(state, key) {
    state.selectedComponentKey = key;
  },
  setEditingViewState(state, payload) {
    if (payload.component.key === 'general') {
      state.editingView = {
        ...state.editingView,
        ...omit(payload, ['component']),
      };

      return;
    }

    state.editingView = {
      ...state.editingView,
      page_state_components: {
        ...state.editingView.page_state_components,
        [payload.component.key]: {
          ...state.editingView.page_state_components[payload.component.key],
          ...omit(payload, ['component']),
        },
      },
    };

    refreshFieldIds(state.editingView);
    cleanUpPageStateComponents(state.editingView);
  },
  cleanEditingView(state) {
    state.view = {};
    state.editingView = {};
    state.permissions = [];
    state.selectedComponentKey = 'general';
  },
};

export const actions = {
  async loadBuilderView({ commit, dispatch, state }, { viewId, refresh = false } = {}) {
    const { records, view: publishedViewData } = extractViewRecords(
      await this.$api.$get(`/builder/views/${viewId}`),
    );

    if (isEqual(publishedViewData, state.view)) return;

    commit('updateViewData', publishedViewData);

    if (refresh) {
      if (window) window.dispatchEvent(new Event('global:beforeViewRefresh'));
    } else {
      commit('records/reset', null, { root: true });
    }

    if (publishedViewData.page_data_default) {
      if (state.view.id !== publishedViewData.id) commit('records/resetViewFilters', null, { root: true });

      await Promise.all([
        commit('setView', publishedViewData),
        dispatch('view/fakeLoadBuilderView', publishedViewData, { root: true }),
        commit('records/loadRecordsByView', { ...publishedViewData, records }, { root: true }),
      ]);

      if (refresh && window) window.dispatchEvent(new Event('global:afterViewRefresh'));
      return;
    }

    const sandboxViewId = publishedViewData.sandbox_view_id;
    const { records: sandboxRecords, view: sandboxViewData } = extractViewRecords(
      await this.$api.$get(`/builder/views/${sandboxViewId}`),
    );

    // reset filters only if the new view is different from the current one
    // when opening a record from an index of records the view will be loaded again
    // and the filters should not be reset, because it is loaded asynchronously and can be overrided
    if (state.view.id !== sandboxViewId) commit('records/resetViewFilters', null, { root: true });

    commit('setLocale', this.$i18n.locale);
    commit('setView', sandboxViewData);
    await dispatch('view/fakeLoadBuilderView', publishedViewData, { root: true });
    commit('records/loadRecordsByView', { records: sandboxRecords, ...sandboxViewData }, { root: true });

    if (refresh && window) window.dispatchEvent(new Event('global:afterViewRefresh'));
  },
  async loadViews({ commit, rootGetters }) {
    const data = await this.$api.$get('/builder/views');

    const categories = rootGetters['workspace/categoryHierarchy'];
    const enrichedViews = enrichViewData(data, categories);

    return commit('setViews', enrichedViews);
  },
  async loadViewPermissions({ commit, state }) {
    const params = {
      params: {
        object_type: 'View',
        object_id: state.editingView.id,
      },
    };
    const data = await this.$api.$get('/permissions', params);

    return commit('setPermissions', data);
  },
  async saveEditingView({ commit, dispatch, state, rootState }, { afterSave = null } = {}) {
    // TODO: improve this to update only the difference between the original view and the editing view
    // It cannot be done yet because the endpoint always expect the entire view object to be sent
    const payload = omit(state.editingView, ['layoutOptions', 'fields', 'recordIds', 'sheet', 'subviews', 'has_changes', 'fields_ids']);

    const beforeViewChangeCopy = cloneDeep(state.view);

    try {
      await this.$api.$patch(`/builder/views/${state.editingView.id}`, payload);
    } catch (error) {
      commit('setView', beforeViewChangeCopy);
      throw error;
    }

    commit('setView', state.editingView);

    if (rootState.view.view.id) {
      await dispatch('loadBuilderView', { viewId: rootState.view.view.id });
    }

    if (afterSave) afterSave();
  },
  async addPermissions({ commit, state }, permissions) {
    const data = await Promise.all(permissions.map(permission => this.$api.$post('/permissions', permission)));
    commit('setPermissions', [...state.permissions, ...data.flat()]);
    commit('setEditingViewHasChanges');
  },
  async removePermissions({ commit, state }, permissionIds) {
    await Promise.all(permissionIds.map(id => this.$api.$delete(`/permissions/${id}`)));
    commit('setPermissions', state.permissions.filter(({ id }) => !permissionIds.includes(id)));
    commit('setEditingViewHasChanges');
  },
  async publish({ state, commit, dispatch, rootState }) {
    const publishedViewData = await this.$api.$post(`/builder/views/${state.editingView.id}/publish`);

    commit('updateViewData', publishedViewData);
    await dispatch('loadBuilderView', { viewId: rootState.view.view.id });
  },
  async discard({ state, commit, dispatch, rootState }) {
    const discardedViewData = await this.$api.$post(`/builder/views/${state.editingView.id}/discard_changes`);

    commit('updateViewData', discardedViewData);
    await dispatch('loadBuilderView', { viewId: rootState.view.view.id });
  },
  async deleteView({ state, commit }, viewId) {
    await this.$api.$delete(`/builder/views/${viewId}`);

    const updatedViews = state.views.filter(view => view.id !== viewId);
    commit('setViews', updatedViews);

    if (state.editingView.id === viewId) {
      commit('cleanEditingView');
    }
  },
};
