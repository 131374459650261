export const state = () => ({
  records: [],
  invalidCells: [],
});

export const getters = {
};

export const mutations = {
  initTable(state) {
    state.invalidCells = [];
  },
  appendInvalidCell(state, cell) {
    state.invalidCells.push(cell);
  },
  removeInvalidCell(state, cell) {
    state.invalidCells = state.invalidCells.filter(c => c !== cell);
  },
};

export const actions = {
};
