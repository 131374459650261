<template>
  <v-tooltip
    v-model="tooltipValue"
    :value="disabled ? false : undefined"
    :activator="activatorElement"
    :open-on-hover="trigger === 'hover'"
    :open-on-focus="trigger === 'hover'"
    :open-on-click="trigger === 'click'"
    :offset-overflow="true"
    :open-delay="(trigger === 'hover' && instant) ? 0 : 300"
    :top="position === 'top'"
    :bottom="position === 'bottom'"
    :left="position === 'left'"
    :right="position === 'right'"
    :nudge-right="shiftX"
    :nudge-top="shiftY"
    :max-width="maxWidth"
    :disabled="disabled"
    :transition="shakeOnOpen ? 'shake-transition' : 'fade-transition'"
    content-class="deck-tooltip__content-wrapper"
    z-index="203"
    class="deck-tooltip"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{}">
      <!-- @slot Default slot where the first child is rendered as the trigger element -->
      <slot />
    </template>

    <div class="deck-tooltip__content" :class="contentClass">
      <!-- @slot Optional slot that can be used to customize the tooltip with markup. Will override `text`-->
      <slot name="content">
        <div
          v-if="kind !== 'plain' && kindIconMapping[kind]"
          class="d-flex align-center g-3"
        >
          <deck-icon
            :name="kindIconMapping[kind].name"
            :color="kindIconMapping[kind].color"
            kind="solid"
          />
          <p class="mb-0">
            {{ text }}
          </p>
        </div>

        <p
          v-else
          class="mb-0"
        >
          {{ text }}
        </p>
      </slot>
    </div>
  </v-tooltip>
</template>

<script>
import { ref, useSlots, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
/**
 * A wrapper around Vuetify's `v-tooltip` component with custom styles, common props and sensible defaults.
 * It will automatically detect the activator element as the first element of the default slot and apply the tooltip to it.
 */
export default {
  name: 'DeckTooltip',
  components: {
    DeckIcon: () => import('~/deck/icon'),
  },

  props: {
    /**
     * A text content to display inside the tooltip.
     * @type {string}
     */
    text: {
      type: String,
      default: '',
    },

    /**
     * The kind of tooltip to display. When not `plain` will display an icon next to the text.
     * Attention when using `info` as it is a well established pattern for the `deck-hinter`!
     * It will be overriden if using the `content` slot.
     * @type {'plain' | 'error' | 'warning' | 'success' | 'info' | string}
     * @default 'plain'
     */
    kind: {
      type: String,
      default: 'plain',
    },

    /**
     * Determine how the tooltip is triggered.
     * @type {'hover' | 'click' | string}
     * @default 'hover'
     */
    trigger: {
      type: String,
      default: 'hover',
      validator: value => ['click', 'hover'].includes(value),
    },

    /**
     * Whether to show the tooltip instantly on hover, or with a pre-defined delay.
     * @type {boolean}
     * @default false
     */
    instant: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines the position of the tooltip relative to the activator element.
     * @type {'top' | 'bottom' | 'left' | 'right' | string}
     * @default 'top'
     */
    position: {
      type: String,
      default: 'top',
      validator: value => ['top', 'bottom', 'left', 'right'].includes(value),
    },

    /**
     * Determines the amount of pixels to shift the tooltip from its defined position on the x-axis. Negative values are allowed. Strigs will strip any non-numeric characters and effectively shift in pixels only, eg: "100%" will be evaluated as 100 pixels.
     * @type {number | string}
     * @default 0
     */
    shiftX: {
      type: [Number, String],
      default: 0,
    },

    /**
     * Determines the amount of pixels to shift the tooltip from its defined position on the y-axis. Negative values are allowed. Strigs will strip any non-numeric characters and effectively shift in pixels only, eg: "100%" will be evaluated as 100 pixels.
     * @type {number | string}
     * @default 0
     */
    shiftY: {
      type: [Number, String],
      default: 0,
    },

    /**
     * Determines the maximum width of the tooltip.
     * @type {number | string}
     * @default '320px'
     */
    maxWidth: {
      type: [Number, String],
      default: '320px',
    },

    /**
     * CSS class applied to the tooltip content.
     * @type {string}
     * @default ''
     */
    contentClass: {
      type: String,
      default: '',
    },

    /**
     * Whether the tooltip is disabled to prevent triggering it.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Delay in milliseconds for the tooltip to automatically close when trigger is set to `click`. Can be set to 'short' or 'long', or a custom number of milliseconds.
     * @type {'short' | 'long' | string | number}
     * @default undefined
     */
    closeDelayAfterClick: {
      type: [String, Number],
      default: undefined,
      validator: value => ['short', 'long'].includes(value) || Number.isInteger(value),
    },

    /**
     * Whether to shake the tooltip when it opens. Useful to draw attention to warnings or errors, especially when those are triggered by click.
     * @type {boolean}
     * @default false
     */
    shakeOnOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const slots = useSlots();
    const tooltipValue = ref(false);
    const activatorElement = ref(null);
    const closeDelayMapping = {
      short: 2500,
      long: 4000,
    };
    const kindIconMapping = {
      error: {
        name: 'circle-exclamation',
        color: 'error',
      },
      warning: {
        name: 'triangle-exclamation',
        color: 'warning',
      },
      success: {
        name: 'circle-check',
        color: 'success',
      },
      info: {
        name: 'circle-info',
        // TODO: Remove hard-coded color when we can reliably reference vuetify's color namings or when we have our color tokens
        color: '#64b5f6',
      },
    };

    const autoCloseDelay = computed(() => closeDelayMapping[props.closeDelayAfterClick] || props.closeDelayAfterClick);

    let autoCloseDelayTimeout = null;

    // onClickOutside will only automatically cleanup side-effects if the target
    // is the ref itself defined on setup(). Since we're using a ref to the
    // first child of the activator slot from onMounted, we need to manually
    // cleanup using a stop handler.
    let stopOnClickOutsideHandler = null;

    const createOnClickOutsideHandler = () => {
      stopOnClickOutsideHandler = onClickOutside(activatorElement, () => {
        tooltipValue.value = false;
      });
    };

    onMounted(() => {
      activatorElement.value = slots.default()?.[0]?.elm;
    });

    onBeforeUnmount(() => {
      if (autoCloseDelayTimeout !== null) {
        clearTimeout(autoCloseDelayTimeout);
      }

      if (stopOnClickOutsideHandler !== null) {
        stopOnClickOutsideHandler();
      }
    });

    watch(tooltipValue, (newValue) => {
      if (props.trigger === 'click' && autoCloseDelayTimeout !== null) {
        clearTimeout(autoCloseDelayTimeout);
        autoCloseDelayTimeout = null;
      }

      if (newValue === false) {
        /**
         * Emited when the tooltip is closed.
         * @event tooltipClosed
         */
        emit('tooltipClosed');

        if (stopOnClickOutsideHandler !== null) {
          stopOnClickOutsideHandler();
        }
      } else if (newValue === true) {
        /**
         * Emited when the tooltip is opened.
         * @event tooltipOpened
         */
        emit('tooltipOpened');

        if (props.trigger === 'click') {
          createOnClickOutsideHandler();

          if (autoCloseDelay.value) {
            autoCloseDelayTimeout = setTimeout(() => {
              tooltipValue.value = false;
            }, autoCloseDelay.value);
          }
        }
      }
    });

    return {
      tooltipValue,
      activatorElement,
      slots,
      autoCloseDelay,
      kindIconMapping,
    };
  },
};
</script>

<style lang="scss">
.deck-tooltip__content-wrapper {
  padding-inline: 12px !important;
  padding-block: 12px !important;
  line-height: 1.5 !important;
  background-color: #212121 !important;
  border-radius: 12px !important;

  &.v-tooltip__content.v-tooltip__content { // Override v-tooltip while allowing for opacity easing from fade-transition
    opacity: 1;
  }

  &.fade-transition-enter-active,
  &.fade-transition-leave-active {
    transition-duration: 150ms !important;
  }

  &.shake-transition-enter-active {
    transform: translateX(0px) !important;
    opacity: 1 !important;
    transition:
      transform 100ms 75ms cubic-bezier(0.5, 50, 0.5, -50),
      opacity 100ms ease-in-out !important
    ;
  }

  &.shake-transition-leave-active {
    transition:
      transform 0ms 100ms linear,
      opacity 100ms ease-in-out !important
    ;
  }

  &.shake-transition-enter,
  &.shake-transition-leave-to {
    transform: translateX(-1px) !important;
    opacity: 0 !important;
  }
}
</style>
