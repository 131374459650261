/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import { escapeRegExp } from 'lodash';
import { calculateFieldNameWithGroup } from '../fields';
import { slugify } from '../string';

export const MENTION_CHARS_MAP = {
  field: '@',
  chain: '.',
  step: '&',
  formula_function: '$',
};

// ---------------------------------------- //
// Mention tag to readable notation parsers //
// ---------------------------------------- //

const fieldTagToNotationParser = (mentionElement) => {
  mentionElement.outerHTML = `{{${mentionElement.dataset.id}}}`;
};

const chainTagToNotationParser = (mentionElement) => { mentionElement.outerHTML = `.{{${mentionElement.dataset.id}}}`; };

const stepTagToNotationParser = (mentionElement) => { mentionElement.outerHTML = `{{${mentionElement.dataset.id}}}`; };

const formulaFunctionTagToNotationParser = (mentionElement) => { mentionElement.outerHTML = mentionElement.dataset.value; };

// ---------------------------------------- //
// Readable notation to mention tag parsers //
// ---------------------------------------- //

const defaultTag = (option, index, char) => (
  `<span class="mention" data-index="${
    index
  }" data-denotation-char="${
    char
  }" data-id="${
    option.id
  }" data-value="${
    option.value
  }"><span contenteditable="false"><span class="ql-mention-denotation-char">${
    char
  }</span>${
    option.value
  }</span></span>`
);

const formulaTag = option => `<span class="mention" data-id="${option.id}" data-value="${option.value}" data-denotation-char="${MENTION_CHARS_MAP.formula_function}">${option.value}</span>`;

const fieldNotationToTagParser = (html, options) => {
  let parsed = html;

  options.forEach((option, index) => {
    const regex = new RegExp(`({{(step|field)_id:[A-Za-z0-9-]*}}\\s?)?((\\.\\s?)?{{${escapeRegExp(option.id)}}})`, 'g');

    const matches = [...parsed.matchAll(regex)];

    matches.forEach((match) => {
      // match[4] is the (\\.\\s?)? --> it will exist when there is a chain reference.
      // match[3] is the ((\\.\\s?)?{{${option.id}}}), which is like {{field_id:123}} for non-chains and .{{field_id:123}} for chains
      const char = match[4] ? MENTION_CHARS_MAP.chain : MENTION_CHARS_MAP.field;
      parsed = parsed.replace(match[3], defaultTag(option, index, char));
    });
  });

  return parsed;
};

const chainNotationToTagParser = (html, _options) => html;

const stepNotationToTagParser = (html, options) => {
  let parsed = html;

  options.forEach((option, index) => {
    const regex = new RegExp(`{{${escapeRegExp(option.id)}}}`, 'g');

    const matches = [...parsed.matchAll(regex)];

    matches.forEach((match) => {
      parsed = parsed.replace(match[0], defaultTag(option, index, MENTION_CHARS_MAP.step));
    });
  });

  return parsed;
};

const formulaFunctionNotationToTagParser = (html, options) => {
  let parsed = html;

  options.forEach((option) => {
    const regex = new RegExp(`(${escapeRegExp(option.value)})(\\s*?\\()`, 'g');
    parsed = parsed.replaceAll(regex, `${formulaTag(option)}$2`);
  });

  return parsed;
};

// ---------------------------------------- //
// Mention items list                       //
// ---------------------------------------- //

export const getFieldItemsList = async (text, mentionsByStrategy) => {
  const parentFieldId = getFilterParentFieldId(text, MENTION_CHARS_MAP.field); // if @ is called inside filter

  if (parentFieldId) {
    const getListItems = mentionsByStrategy.field?.findChainItems || (_ => []);

    const list = await getListItems(parentFieldId);

    return { renderStrategy: 'field', list };
  }

  return { renderStrategy: 'field', list: mentionsByStrategy.field?.items || [] };
};

const getChainItemsList = async (text, mentionsByStrategy) => {
  const { strategy: renderStrategy, id } = getParentMention(text);

  if (renderStrategy === UNDEFINED_STRATEGY) return { renderStrategy };

  const getListItems = mentionsByStrategy[renderStrategy]?.findChainItems || (_ => []);

  const list = await getListItems(id);

  return { renderStrategy, list };
};

const getStepItemsList = (_, mentionsByStrategy) => ({ renderStrategy: 'step', list: mentionsByStrategy.step?.items || [] });

const getFormulaFunctionItemsList = (_, mentionsByStrategy) => ({ renderStrategy: 'formula_function', list: mentionsByStrategy.formula_function?.items || [] });

// ---------------------------------------- //
// Strategies map                           //
// ---------------------------------------- //

export const MENTION_STRATEGIES = {
  field: {
    parsers: {
      tagToNotation: fieldTagToNotationParser,
      notationToTag: fieldNotationToTagParser,
    },
    optionsFormatter: options => options.map(field => ({ id: `field_id:${field.id}`, value: calculateFieldNameWithGroup(field) })),
    getItemsList: getFieldItemsList,
  },
  chain: {
    parsers: {
      tagToNotation: chainTagToNotationParser,
      notationToTag: chainNotationToTagParser,
    },
    getItemsList: getChainItemsList,
  },
  step: {
    parsers: {
      tagToNotation: stepTagToNotationParser,
      notationToTag: stepNotationToTagParser,
    },
    optionsFormatter: options => options.map(({ id, value, name }) => ({ id: `${name ? 'field' : 'step'}_id:${id}`, value: value || name })),
    getItemsList: getStepItemsList,
  },
  formula_function: {
    parsers: {
      tagToNotation: formulaFunctionTagToNotationParser,
      notationToTag: formulaFunctionNotationToTagParser,
    },
    optionsFormatter: options => options,
    getItemsList: getFormulaFunctionItemsList,
  },
};

// ---------------------------------------- //
// Helpers                                  //
// ---------------------------------------- //

const UNDEFINED_STRATEGY = 'undefined_strategy';

export const getStrategyByMentionChar = char => Object.keys(MENTION_CHARS_MAP).find(type => MENTION_CHARS_MAP[type] === char) || UNDEFINED_STRATEGY;

const parseTagToNotation = (mentionElement) => {
  const strategy = getStrategyByMentionChar(mentionElement.dataset.denotationChar);
  if (strategy === UNDEFINED_STRATEGY) return;

  const { parsers: { tagToNotation: parser } } = MENTION_STRATEGIES[strategy];

  parser(mentionElement);
};

const parseNotationToTag = (html, strategy, list) => {
  if (!Object.keys(MENTION_CHARS_MAP).includes(strategy)) return html;

  // this exist because the formula gem doesnt read dot notation on steps + fields
  // like we use it at beginning: {{step_id:[step_id]}}.{{field_id:[field_id]}}
  // It expects something like {{step_id--[stepid]__result--[field_id]}} in a single mustache.
  // We use dot notation to improve usability and after using it, we parse to a friendly format to formula.
  const parsed = parseStepsFromFormulaNotation(html);

  const { optionsFormatter } = MENTION_STRATEGIES[strategy];
  const { parsers: { notationToTag: parser } } = MENTION_STRATEGIES[strategy];
  const options = optionsFormatter(list);

  return parser(parsed, options);
};

const getFilterStartIndex = (expression, mentionChar) => {
  // Checks matching brackets to find filter start/end in input string
  const bracketStack = [];

  return expression.split('').reverse().findIndex((item) => {
    if (item === '[') {
      if (!bracketStack.length) return true;

      if (bracketStack[bracketStack.length - 1] === ']') {
        bracketStack.pop();
      }

      if (!bracketStack.length && mentionChar === MENTION_CHARS_MAP.chain) return true; // Look for nearest closed filter
    }

    if (item === ']') {
      bracketStack.push(item);
    }

    return false;
  });
};

export const getFilterParentFieldId = (expression, mentionChar) => {
  let parentFieldId = null;

  if (expression) {
    const filterStartIndex = getFilterStartIndex(expression, mentionChar);
    if (filterStartIndex === -1) return null;

    const expressionUntilLastOpenFilter = expression.slice(0, expression.length - filterStartIndex - 1);
    const regex = /\{\{(field_id:([A-Za-z0-9-]*))}}(?!(.))/g;
    const match = expressionUntilLastOpenFilter.matchAll(regex).next();

    parentFieldId = match.value ? match.value[2] : null;
  }

  return parentFieldId;
};

const getParentMention = (text) => {
  // ---------------------------------------- //
  // Gets parent mention strategy from a dot  //
  // ---------------------------------------- //
  const regex = /({{((field|step)_id:([A-Za-z0-9-]*))}}\s?\.\s?)*{{((field|step)_id:([A-Za-z0-9-]*))}}\s?(\[.*\])?\.(?!([{{.]))/g; // regex to match the strategy and the new dot
  const match = text.matchAll(regex).next();

  if (match.value) {
    // regex matches
    // [6] is the strategy (field or step) of the mustache before the last dot
    // [7] is the referred strategy's id
    // [8] is the filter, if present
    const strategy = match.value[6];
    const id = match.value[7];
    const filter = match.value[8];

    // this line disallows multiple chains for step fields (backend doesnt support it).
    // If you remove it, front will support it.
    if (strategy === 'field' && match.value[0].includes('step')) return;

    if (!filter || strategy === 'step') return { strategy, id };

    return { strategy, id: getFilterParentFieldId(text, MENTION_CHARS_MAP.chain) };
  }

  return { strategy: UNDEFINED_STRATEGY };
};

const searchMentions = (options, search, renderList) => {
  if (search.length === 0) {
    renderList(options, search);
  } else {
    const matches = [];

    options.forEach((mentionOption) => {
      if (slugify(mentionOption.value).includes(slugify(search))) matches.push(mentionOption);
    });

    renderList(matches, search);
  }
};

const unnestContextSchema = schema => Object.entries(schema).reduce(
  (acc, value) => {
    const [id, schemaValue] = value;

    acc[id] = schemaValue;

    if (schemaValue.type === 'hash') {
      acc = { ...acc, ...unnestContextSchema(schemaValue.properties) };
    }

    return acc;
  },
  {},
);

const parseStepsToFormulaNotation = (html) => {
  let parsed = html;

  const regex = /{{(step_id:([A-Za-z0-9-]*))}}\s?\.\s?{{(field_id:([A-Za-z0-9-]*))}}/g;

  const matches = [...html.matchAll(regex)];

  matches.forEach((match) => {
    const stepId = match[2];
    const fieldId = match[4];

    parsed = parsed.replace(match[0], `{{step_id--${stepId}__result--${fieldId}}}`);
  });

  return parsed;
};

const parseStepsFromFormulaNotation = (html) => {
  let parsed = html;

  const regex = /{{step_id--([A-Za-z0-9-]*)__result--([A-Za-z0-9-]*)}}/g;

  const matches = [...html.matchAll(regex)];

  matches.forEach((match) => {
    const stepId = match[1];
    const fieldId = match[2];

    parsed = parsed.replace(match[0], `{{step_id:${stepId}}}.{{field_id:${fieldId}}}`);
  });

  return parsed;
};

// ---------------------------------------- //
// Exposed methods                          //
// ---------------------------------------- //

export const replaceMentionNotationsToTag = (html, mentions, contextSchema = {}) => {
  let parsed = html;

  // if there is a context_schema, the field mentions should be parsed by it

  const unnestedSchema = unnestContextSchema(contextSchema);

  Object.entries(unnestedSchema).forEach(([id, schema]) => {
    const strategy = id.includes('step') ? 'step' : 'field';
    parsed = parseNotationToTag(parsed, strategy, [{ id, ...schema }]);
  });

  // mentions is expected to be something like:
  // { field: { items: [{ id: 1, value: 'Nome' }, { id: 2, value: 'CPF' }], ... } }
  Object.entries(mentions).forEach(([strategy, { items }]) => {
    parsed = parseNotationToTag(parsed, strategy, items);
  });

  return parsed;
};

export const replaceMentionTagsToNotation = (html, parser = text => text) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const mentionsHtmlCollection = doc.body.getElementsByClassName('mention');

  // Isso abaixo não é um loop infinito.
  // O mentionsHtmlCollection não é um array, é um HTMLCollection https://developer.mozilla.org/en-US/docs/Web/API/HTMLCollection
  // O HTMLCollection é um objeto "vivo", reativo, que é alterado toda vez que o documento é alterado.
  // Então toda vez que modificamos o HTML (e apagamos o <span class='mention'>), o mentions diminui de tamanho.

  while (mentionsHtmlCollection.length > 0) {
    parseTagToNotation(mentionsHtmlCollection[0]);
  }

  // this exist because the formula gem doesnt read dot notation on steps + fields
  // like we use it at beginning: {{step_id:[step_id]}}.{{field_id:[field_id]}}
  // It expects something like {{step_id--[stepid]__result--[field_id]}} in a single mustache.
  // We use dot notation to improve usability and after using it, we parse to a friendly format to formula.
  const parsedHTML = parseStepsToFormulaNotation(doc.body.innerHTML);

  return parser(parsedHTML);
};

class GetItemsListError extends Error {
  constructor(strategy, originalError) {
    super('Error while getting items list');
    this.originalError = originalError;

    this.errorData = {
      ...originalError.errorData,
      strategy,
    };
  }
}

export const renderMentionsList = async (search, renderList, mentionChar, mentionsByStrategy, text) => {
  const strategy = getStrategyByMentionChar(mentionChar);

  if (strategy === UNDEFINED_STRATEGY) return;

  let renderStrategy = UNDEFINED_STRATEGY;
  let list = [];

  try {
    const result = await MENTION_STRATEGIES[strategy].getItemsList(text, mentionsByStrategy);
    renderStrategy = result.renderStrategy;
    list = result.list;
  } catch (error) {
    throw new GetItemsListError(strategy, error);
  }

  if (renderStrategy === UNDEFINED_STRATEGY) return;

  const { optionsFormatter } = MENTION_STRATEGIES[renderStrategy];

  searchMentions(optionsFormatter(list), search, renderList);
};

export const mentionButtonsToRender = strategies => Object.entries(MENTION_CHARS_MAP).filter(([strategy, _]) => strategies.includes(strategy)).map(([_, char]) => char);
