import { buildBaseInfoComponent } from './_base';

export const buildFieldInfoComponent = ({
  columns = 1,
  fieldId,
  fieldType,
  id,
  label,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    field_id: fieldId,
    field_type: fieldType,
    label,
    type: 'field',
  };

  if (id) component.id = id;

  return component;
};

export const buildFieldInfoComponentFromField = (field, overrides = {}) => buildFieldInfoComponent({
  fieldId: field.id,
  fieldType: field.type,
  label: field.name,
  ...overrides,
});
