// the widest letter in roboto font is the letter "W"
export const ROBOTO_FONT_WIDEST_WIDTH_BY_SIZE = {
  8: 7.5625,
  9: 8.5,
  10: 9.453125,
  11: 10.390625,
  12: 11.328125,
  13: 12.28125,
  14: 13.21875,
  15: 14.171875,
  16: 15.109375,
  17: 16.046875,
  18: 17,
};
