import { slugify } from '../string';

/**
 * Sets a CSS property on the root element of the document.
 *
 * @param {string} name - The name of the CSS custom property to set.
 * @param {string} value - The value to set the CSS custom property to.
 */
export const setRootCssProp = (name, value) => {
  const cssProp = `--${slugify(name)}`;
  document.documentElement.style.setProperty(cssProp, value);
};

/**
 * Removes a CSS property from the root element of the document.
 *
 * @param {string} name - The name of the CSS property to remove.
 */
export const clearRootCssProp = (name) => {
  const cssProp = `--${name}`;
  document.documentElement.style.removeProperty(cssProp);
};

/**
 * Gets the distances between the element edges and to the edges of the viewport relative to the axis of the given edge.
 *
 * @param {HTMLElement} element - The element to calculate distances for.
 * @returns {Object} An object containing the distances between the element edges to the edges of the viewport in the same axis. Eg: `topToStart` key can be interpreted as: the distance of the top edge of the element to the start of Y axis of the viewport (top of viewport).
 */
export const getElementEdgeDistancesToViewport = (element) => {
  if (!element) return 0;

  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const topToStart = element.offsetTop;
  const topToEnd = windowHeight - topToStart;

  const bottomToStart = topToStart + element.offsetHeight;
  const bottomToEnd = topToEnd - element.offsetHeight;

  const leftToStart = element.offsetLeft;
  const leftToEnd = windowWidth - leftToStart;

  const rightToStart = leftToStart + element.offsetWidth;
  const rightToEnd = leftToEnd - element.offsetWidth;

  return {
    topToStart,
    topToEnd,
    bottomToStart,
    bottomToEnd,
    leftToStart,
    leftToEnd,
    rightToStart,
    rightToEnd,
  };
};

/**
 * Gets the element height including margins and paddings.
 *
 * @param {HTMLElement} element - The element to get the height for.
 * @returns {Number} The element height including margins and paddings.
 */
export const getElementOuterHeight = (element) => {
  const styles = window.getComputedStyle(element);

  return element.offsetHeight + parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
};
