export const DEFAULT_PAGE_STATE_COMPONENTS = Object.freeze({
  index: {
    main_section: {
      main_content: [],
    },
  },
  single_item: {
    main_section: {
      main_content: [],
    },
  },
});
