import { cloneDeep, isEqual, isEmpty } from 'lodash';
import { MissingRequiredFieldsError } from './missing-required-fields-error';
import { SubviewHasNoInsertPermissionError } from './subview-has-no-insert-permission-error';

const transformValueByFieldType = ({
  value,
  type,
  subview,
  rootGetters,
  getRecordByIdGetter,
}) => {
  switch (type) {
    case 'Date':
    case 'DateTime':
      return value?.value;
    case 'Attachment':
      return (value || []).map(({ attachment_id: attachmentId }) => attachmentId);
    case 'CoverImage':
      return (value || []).map(({ cover_image_id: coverImageId }) => coverImageId);
    case 'Link':
      if (subview) {
        if (subview.permit_record_update || subview.permit_record_insert) {
          return (value || []).map(({ foreign_record_id: foreignRecordId }) => {
            const record = rootGetters[getRecordByIdGetter](foreignRecordId) || {};

            if (record.isNew && !subview.permit_record_insert) throw new SubviewHasNoInsertPermissionError();

            // eslint-disable-next-line no-use-before-define
            const recordData = transformRecordData(
              record,
              subview.fields,
              subview.id,
              rootGetters,
              getRecordByIdGetter,
            );

            if (record.isNew) {
              recordData.operation_type = 'insert';
            } else if (subview.permit_record_update) {
              recordData.operation_type = 'update';
            } else {
              recordData.operation_type = 'link';
            }

            return recordData;
          });
        }

        const hasAnyNewRecord = (value || []).some(({ foreign_record_id: foreignRecordId }) => {
          const record = rootGetters[getRecordByIdGetter](foreignRecordId) || {};
          return record.isNew;
        });

        if (hasAnyNewRecord) {
          throw new SubviewHasNoInsertPermissionError();
        }
      }

      return (value || []).map(({ foreign_record_id: foreignRecordId }) => foreignRecordId);
    case 'Select':
    case 'MultipleSelect':
      return (value || []).map(({ select_option_id: selectOptionId }) => selectOptionId);
    case 'Number':
      if (!value?.value) return null;
      return value;
    default:
      return value;
  }
};

export function transformRecordData(recordData, fields, viewId, rootGetters, getRecordByIdGetter) {
  const data = recordData.isNew ? {} : { id: recordData.id };

  fields.forEach((field) => {
    const { id: fieldId, type, calculated } = field;

    // Calculated fields are not editable
    if (calculated) return;
    // Lookup and Formula fields are not editable
    if (['Lookup', 'Formula'].includes(type)) return;
    // User field is not editable through this interface, except for new records
    if (type === 'User' && !recordData.isNew) return;

    const value = recordData[fieldId];

    const subview = type === 'Link' ? rootGetters['view/findSubview'](viewId, fieldId) : null;
    data[fieldId] = transformValueByFieldType({ value, type, subview, rootGetters, getRecordByIdGetter });
  });

  return data;
}

export const transformDraftRecordData = (recordData, fields, viewId, rootGetters) => transformRecordData(recordData, fields, viewId, rootGetters, 'records/draftRecordById');

export const transformRawRecordData = (recordData, fields, viewId, rootGetters) => transformRecordData(recordData, fields, viewId, rootGetters, 'records/rawRecordById');

export const removeUnchangedDataFromDraftRecord = (draftRecord, rawRecord, requiredFields = []) => {
  const result = cloneDeep(draftRecord);

  Object.keys(draftRecord).forEach((key) => {
    if (isEqual(draftRecord[key], rawRecord[key]) && !requiredFields.includes(key)) {
      delete result[key];
    }
  });

  return result;
};

const missingRequiredFields = (record, requiredFields) => requiredFields.some(fieldId => isEmpty(record[fieldId]));

export const checkForMissingRequiredFields = (record, requiredFields) => {
  if (missingRequiredFields(record, requiredFields)) {
    throw new MissingRequiredFieldsError(record, requiredFields);
  }
};
