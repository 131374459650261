import { builder } from './builder';
import { dummyData } from './dummyData';
import { publicRoutes } from './publicRoutes';
import { sheets } from './sheets';
import { views } from './views';
import { workflows } from './workflows';
import { workflowSteps } from './workflowSteps';
import { workflowTriggers } from './workflowTriggers';

export default $axios => ({
  builder: builder($axios),
  dummyData: dummyData($axios),
  publicRoutes: publicRoutes($axios),
  sheets: sheets($axios),
  views: views($axios),
  workflows: workflows($axios),
  workflowSteps: workflowSteps($axios),
  workflowTriggers: workflowTriggers($axios),
});
