import { floatifyNumber, formatWithThousandSeparator } from '~/assets/javascript/utils/number';
import { COLORS } from '~/assets/javascript/constants/colors';

export const DECIMAL_SEPARATOR_MAPPING = Object.freeze({
  pt: ',',
  'pt-BR': ',',
  en: '.',
  'en-US': '.',
});

export const CHECKLIST_ESCAPE_CHARS_MAPPING = Object.freeze({
  '"': '&quot;',
  '\'': '&apos;',
  ':': '&colon;',
  ',': '&comma;',
  '\\': '&bsol;',
  '|': '&vert;',
});

export const FIELD_TYPE_KEY_DISPLAY_MAPPING = Object.freeze({
  User: 'username',
  CoverImage: 'filename',
  Attachment: 'filename',
  Link: 'foreign_record_display_name',
  Lookup: 'foreign_record_display_name',
  Checklist: 'items',
  Select: 'select_option_display_name',
  MultipleSelect: 'select_option_display_name',
  Date: 'display_name',
  DateTime: 'display_name',
  Number: 'value',
  Formula: 'value',
});

export const FIELD_TYPE_KEY_SORT_MAPPING = Object.freeze({
  ...FIELD_TYPE_KEY_DISPLAY_MAPPING,
  Date: 'value',
});

export const FIELD_TYPE_FUNCTION_DISPLAY_MAPPING = Object.freeze({
  Number: (field, value, _mapping) => {
    let formattedValue = floatifyNumber(value, field.options.number_options.precision);
    const decimalSeparator = DECIMAL_SEPARATOR_MAPPING[field.i18nOptions.locale];
    const thousandSeparator = decimalSeparator === ',' ? '.' : ',';

    formattedValue = formattedValue.toFixed(field.options.number_options.precision);

    if (field.options.number_options.precision > 0) {
      formattedValue = formattedValue.replace('.', decimalSeparator).split(decimalSeparator);
      formattedValue = [formatWithThousandSeparator(formattedValue[0], thousandSeparator), formattedValue[1]].join(decimalSeparator);
    } else {
      formattedValue = formatWithThousandSeparator(formattedValue, thousandSeparator);
    }

    return [field.options.number_options.prefix, formattedValue, field.options.number_options.suffix].join(' ').trim();
  },
  Lookup: (field, value, mapping) => {
    if (field.options.lookup_field_type === 'Date') {
      const dateDisplayKey = mapping.Date;

      return value[dateDisplayKey];
    }

    return value;
  },
});

export const DATE_FORMATS = Object.freeze([
  'DD/MMMM',
  'DD/MM/YYYY',
  'MMMM/YYYY',
  'MM/YYYY',
  'YYYY',
]);

export const DATE_TIME_FORMATS = Object.freeze([
  ...DATE_FORMATS,
  'DD/MM/YYYY HH:mm',
  'DD/MM HH:mm',
  'HH:mm',
]);

// The order of DATE_FORMATS constant doesn't work :/
// The parse of '07/2016' using the DATE_FORMATS returns `Invalid date'
// So the array order must be the following to work
export const MOMENT_DATE_SORTED_FORMATS = Object.freeze([
  'YYYY',
  'MM/YYYY',
  'DD/MMMM',
  'MMMM/YYYY',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm',
  'DD/MM HH:mm',
  'HH:mm',
]);

/**
 * Converter Map for the date formats received from the API.
 *
 * Keys are the FORMATS expected to render on the UI, received from the API
 *
 * Values are the format of the actual VALUES also received from the API, to
 * help moment.js parse the date correctly on all Browsers safely.
 * (I'm looking at you, Safari)
 */
export const DATE_TIME_RECEIVED_FORMATS_MAPPING = Object.freeze({
  '%Y': 'YYYY',
  '%d/%B': 'MM-DD',
  '%B/%Y': 'YYYY-MM',
  '%m/%Y': 'YYYY-MM',
  '%d/%m/%Y': 'YYYY-MM-DD',
  '%d/%B %H:%M': 'MM-DD[T]HH:mm',
  '%d/%m/%Y %H:%M': 'YYYY-MM-DD[T]HH:mm',
  '%H:%M': 'HH:mm',
});

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const DATE_FORMATS_SUBMIT_MAPPING = Object.freeze(DATE_TIME_FORMATS.reduce((accumulator, current) => {
  accumulator[current] = current
    .replaceAll('DD', '%d')
    .replaceAll('MMMM', '%B')
    .replaceAll('MM', '%m')
    .replaceAll('YYYY', '%Y')
    .replaceAll('HH', '%H')
    .replaceAll('mm', '%M');

  return accumulator;
}, {}));

export const FORMULA_ALLOWED_FIELD_TYPES = Object.freeze([
  'Checklist',
  'Number',
  'Lookup',
  'Date',
  'DateTime',
  'Link',
  'Select',
  'MultipleSelect',
  'String',
]);

export const FORMULA_FUNCTIONS = Object.freeze([
  { id: 1, value: 'ABS' },
  { id: 2, value: 'AND' },
  { id: 3, value: 'ARRAYCOMPACT' },
  { id: 4, value: 'ARRAYFLATTEN' },
  { id: 5, value: 'ARRAYJOIN' },
  { id: 6, value: 'ARRAYUNIQUE' },
  { id: 7, value: 'AVERAGE' },
  { id: 8, value: 'CEILING' },
  { id: 9, value: 'COUNT' },
  { id: 10, value: 'COUNTA' },
  { id: 11, value: 'COUNTALL' },
  { id: 12, value: 'COUNTIF' },
  { id: 13, value: 'DATE' },
  { id: 14, value: 'DATE_DIFF' },
  { id: 15, value: 'DATETIME' },
  { id: 16, value: 'DATETIME_FORMAT' },
  { id: 17, value: 'FIRST' },
  { id: 18, value: 'FLOOR' },
  { id: 19, value: 'IF' },
  { id: 20, value: 'IFERROR' },
  { id: 21, value: 'INT' },
  { id: 22, value: 'INTERVAL' },
  { id: 23, value: 'ISBLANK' },
  { id: 24, value: 'ISWEEKEND' },
  { id: 25, value: 'LAST' },
  { id: 26, value: 'LEFT' },
  { id: 27, value: 'LEN' },
  { id: 28, value: 'LOG' },
  { id: 29, value: 'MAX' },
  { id: 30, value: 'MIN' },
  { id: 31, value: 'MOD' },
  { id: 32, value: 'NOT' },
  { id: 33, value: 'OR' },
  { id: 34, value: 'POWER' },
  { id: 35, value: 'REPLACE' },
  { id: 36, value: 'RIGHT' },
  { id: 37, value: 'ROUND' },
  { id: 38, value: 'ROUNDDOWN' },
  { id: 39, value: 'ROUNDUP' },
  { id: 40, value: 'SPLIT' },
  { id: 41, value: 'SQRT' },
  { id: 42, value: 'STDEV' },
  { id: 43, value: 'SUM' },
  { id: 44, value: 'SUMIF' },
  { id: 45, value: 'TODAY' },
  { id: 46, value: 'WEEKDAY' },
  { id: 47, value: 'XOR' },
]);

export const MASK_INCOMPATIBLE_RULES = Object.freeze(['max_characters']);

export const DEFAULT_MASK_OPTIONS = Object.freeze({
  noMask: {
    mask: null,
    example: null,
  },
  CEP: {
    mask: '#####-###',
    example: '00000-000',
  },
  CNPJ: {
    mask: '##.###.###/####-##',
    example: '12.345.678/1234-12',
  },
  CPF: {
    mask: '###.###.###-##',
    example: '123.456.789-00',
  },
  PhoneBR: {
    mask: '(##) #####-####',
    example: '(00) 99999-9999',
  },
  // BankAgencyBR: {
  //   mask: ['###', '####', '####-X'],
  //   example: '1234-5',
  // },
  // BankAccountBR: {
  //   mask: ['####-#', '#####-X', '######-#', '########-#', '#######-##'],
  //   example: '12345678-9',
  // },
});

export const SELECT_OPTIONS_TEMPLATE = Object.freeze({
  NPS: [{
    value: '1',
    internal_value: -1,
    color: COLORS.blue.accent,
  }, {
    value: '2',
    internal_value: -1,
    color: COLORS.blue.accent,
  }, {
    value: '3',
    internal_value: -1,
    color: COLORS.blue.accent,
  }, {
    value: '4',
    internal_value: -1,
    color: COLORS.blue.accent,
  }, {
    value: '5',
    internal_value: -1,
    color: COLORS.blue.accent,
  }, {
    value: '6',
    internal_value: -1,
    color: COLORS.blue.accent,
  }, {
    value: '7',
    internal_value: 0,
    color: COLORS.blue.accent,
  }, {
    value: '8',
    internal_value: 0,
    color: COLORS.blue.accent,
  }, {
    value: '9',
    internal_value: 1,
    color: COLORS.blue.accent,
  }, {
    value: '10',
    internal_value: 1,
    color: COLORS.blue.accent,
  }],
  CSAT: [{
    value: '😟',
    internal_value: -1,
    color: COLORS.red.base,
  }, {
    value: '😐',
    internal_value: 0,
    color: COLORS.yellow.base,
  }, {
    value: '😄',
    internal_value: 1,
    color: COLORS.green.base,
  }],
  Likert: [{
    value: 'Strongly Disagree',
    internal_value: -2,
    color: COLORS.red.dark,
  }, {
    value: 'Disagree',
    internal_value: -1,
    color: COLORS.red.base,
  }, {
    value: 'Neutral',
    internal_value: 0,
    color: COLORS.yellow.base,
  }, {
    value: 'Agree',
    internal_value: 1,
    color: COLORS.green.base,
  }, {
    value: 'Strongly Agree',
    internal_value: 2,
    color: COLORS.green.dark,
  }],
  Stars: [{
    value: '⭐',
    internal_value: 1,
    color: COLORS.blue.accent,
  }, {
    value: '⭐⭐',
    internal_value: 2,
    color: COLORS.blue.accent,
  }, {
    value: '⭐⭐⭐',
    internal_value: 3,
    color: COLORS.blue.accent,
  }, {
    value: '⭐⭐⭐⭐',
    internal_value: 4,
    color: COLORS.blue.accent,
  }, {
    value: '⭐⭐⭐⭐⭐',
    internal_value: 5,
    color: COLORS.blue.accent,
  }],
  YesNoMaybe: [{
    value: 'Yes',
    internal_value: 1,
    color: COLORS.green.base,
  }, {
    value: 'No',
    internal_value: -1,
    color: COLORS.red.base,
  }, {
    value: 'Maybe',
    internal_value: 0,
    color: COLORS.yellow.base,
  }],
});

export const FIELD_CALCULATED_TYPES_ENABLED = Object.freeze([
  'Number',
  'String',
  'Select',
  'MultipleSelect',
]);

export const FORMULA_OPTIONS_KEYS = Object.freeze(['expression', 'context_schema', 'valid_expression']);

export const FIELD_OPTIONS_KEYS = Object.freeze({
  Formula: FORMULA_OPTIONS_KEYS,
});

export const FIELD_VISIBILITY_OPTIONS = Object.freeze({
  PUBLIC: 'public',
  CONFIDENTIAL: 'confidential',
});
