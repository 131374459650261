export const viewToRedirectAfterScopeChange = (allViews, viewInCurrentScope, currentViewId, currentCategoryName) => {
  const viewsInScope = allViews.filter(viewInCurrentScope);

  if (!currentViewId || viewsInScope.map(v => v.id).includes(currentViewId)) return;

  let viewToRedirect = viewsInScope.find(v => v.category?.category_name === currentCategoryName);

  if (!viewToRedirect) {
    viewToRedirect = viewsInScope.length > 0 ? viewsInScope[0] : allViews[0];
  }

  // eslint-disable-next-line consistent-return
  return viewToRedirect;
};
