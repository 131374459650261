import ROUTES from '~/assets/javascript/constants/routes';

export default async function middleware({ redirect, query, localeRoute, params, store, $auth: { user: { workspace: { slug: tenantSlug } } } }) {
  if (query.id) {
    return redirect(localeRoute(
      { name: ROUTES.studio.workflows.routeName, params: { tenantSlug, id: query.id } },
    ));
  }

  await store.dispatch('workspace/loadWorkspace', { builder: true });

  if (params.id) {
    store.dispatch('workspace/setCategoryByWorkflowId', params.id);

    try {
      store.commit('workflows/setStepsVisibility', false);
      await store.dispatch('workflows/loadCurrentWorkflow', params.id);
    } catch (error) {
      if (error?.response?.status !== 404) throw error;

      const firstWorkflowId = store.getters['workspace/firstWorkflowId'];

      if (firstWorkflowId) {
        return redirect(localeRoute(
          {
            name: ROUTES.studio.workflows.routeName,
            params: { tenantSlug, id: firstWorkflowId },
          },
        ));
      }
    }
  } else {
    const firstWorkflowId = store.getters['workspace/firstWorkflowId'];

    if (firstWorkflowId) {
      return redirect(localeRoute(
        {
          name: ROUTES.studio.workflows.routeName,
          params: { tenantSlug, id: firstWorkflowId },
        },
      ));
    }
  }

  store.dispatch('workflows/loadWorkflows');

  return null;
}
