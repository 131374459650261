import { defaultOptions } from '~/plugins/vue-sanitize';

export const sanitizeHtml = (html, sanitizeMethod) => sanitizeMethod(html, {
  ...defaultOptions,
  allowedTags: [
    ...defaultOptions.allowedTags,
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'ol',
    'table',
    'thead',
    'tbody',
    'span',
    'tr',
    'th',
    'td',
    'img',
    'iframe',
    's',
    'code',
    'pre',
  ],
  allowedAttributes: {
    ...defaultOptions.allowedAttributes,
    div: ['style'],
    pre: ['spellcheck'],
    '*': ['data-*', 'class', 'id'],
    img: ['src', 'style', 'alt', 'width', 'height'],
    span: ['contenteditable'],
    iframe: ['frameborder', 'allowfullscreen', 'src'],
    ul: ['data-checked'],
  },
  transformTags: {},
});

export const validateHtml = (html, sanitizeMethod) => {
  const fixedHtml = html
    .replace(/<hr data-.*>/g, match => match.replace('>', ' />'))
    .replace(
      /<img [^<>]+>/g,
      match => match
        .replace('>', ' />')
        .replace('alt=""', 'alt')
        .replace('cursor: nwse-resize;', 'cursor:nwse-resize')
        .replace('height: ', 'height:')
        .replace('width: ', 'width:')
        .replace(' height:', 'height:')
        .replace(' width:', 'width:')
        .replace('px;"', 'px"')
        .replace('style="" ', ''),
    )
    .replaceAll('&nbsp;', ' ');

  const sanitizedHtml = sanitizeHtml(fixedHtml, sanitizeMethod).replaceAll('<br />', '<br>');

  return sanitizedHtml === fixedHtml;
};
