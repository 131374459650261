<i18n lang="yaml">
pt:
  notFound: "{code}! Página não encontrada"
  unauthorized: "{code}! Você não tem permissão para acessar esta pagina"
  error: "Ocorreu um erro"
  logout: "Sair"
en:
  notFound: "{code}! Page not found"
  unauthorized: "{code}! You do not have permission to access this page"
  error: "An error occurred"
  logout: "Log out"
</i18n>
<template>
  <v-row class="pt-10">
    <v-col cols="12">
      <h1 class="text-center">
        {{ errorText }}
      </h1>

      <div class="text-center">
        <img
          src="/img-working.svg"
          alt="error"
          class="my-10"
          style="max-height: 300px; width: 100%"
        >
      </div>

      <div class="text-center mt-6">
        <v-btn
          v-if="error.statusCode === 401"
          color="primary"
          text
          @click="$auth.logoutUser()"
        >
          <v-icon left>
            fa-right-from-bracket fa-regular
          </v-icon>
          {{ $t('logout') }}
        </v-btn>
        <v-btn :to="tenantHomePage" class="zazos-bg lightElevation" rounded dark>
          Home page
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
const AVAILABLE_STATUS_CODES = {
  404: 'notFound',
  401: 'unauthorized',
};

export default {
  name: 'ErrorLayout',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    this.$rollbar.error(`Error Page: ${this.error.statusCode}`, this.error);

    if (!this.$auth.user?.workspace?.slug) {
      this.$auth.logoutUser();
    }

    return this.$buildHead({
      title: this.errorText,
    });
  },
  computed: {
    errorText() {
      return AVAILABLE_STATUS_CODES[this.error.statusCode]
        ? this.$t(AVAILABLE_STATUS_CODES[this.error.statusCode], { code: this.error.statusCode })
        : this.$t('error');
    },
    tenantHomePage() {
      return this.localeRoute({
        name: 't-tenantSlug-m',
        params: { tenantSlug: this.$auth.user?.workspace?.slug },
      });
    },
  },
};
</script>
