import isEmpty from 'lodash/isEmpty';

const DEFAULT_LOCALE = 'pt';

class Module {
  constructor(nuxt) {
    this.$auth = nuxt.$auth;
    this.$segmentIdentifyUser = nuxt.$segmentIdentifyUser;
    this.i18n = nuxt.app.i18n;
    this.locale = DEFAULT_LOCALE;
    this.localePath = nuxt.app.localePath;
    this.router = nuxt.app.router;
  }

  get route() {
    return this.router.currentRoute;
  }

  async handle({ redirectTo, withQuery }) {
    await this.$segmentIdentifyUser();

    this.defineLangFromUser();

    return this.router.push(this.redirectRoute({ redirectTo, withQuery }));
  }

  redirectRoute({ redirectTo, withQuery }) {
    const redirectRoute = redirectTo
      || this.route.query.redirectTo
      || window.localStorage.getItem('redirectTo')
      || this.buildRedirectToPath({ withQuery });

    window.localStorage.removeItem('redirectTo');

    return redirectRoute;
  }

  defineLangFromUser() {
    const { language: defaultLang = DEFAULT_LOCALE } = this.$auth.user?.workspace || {};

    this.locale = defaultLang;
    this.i18n.setLocale(defaultLang);
  }

  buildRedirectToPath({ withQuery }) {
    const localePathParams = {
      name: 't-tenantSlug-m',
      params: {
        tenantSlug: this.$auth.user.workspace.slug,
      },
    };

    if (!isEmpty(this.route.query) && withQuery) {
      localePathParams.query = this.route.query;
    }

    return this.localePath(localePathParams, this.locale);
  }
}

export default Module;
