<template>
  <div class="snackbar">
    <transition-group
      appear
      tag="span"
      name="deck-snackbar--slide-from-right"
      class="snackbar__wrapper"
      @mouseenter.native="hasPausedMessages = true"
      @mouseleave.native="hasPausedMessages = false"
    >
      <deck-snackbar
        v-for="({ message, color, timeout, id }) in messages"
        :key="id"
        :text="message"
        :color="color"
        :timeout="hasPausedMessages ? -1 : timeout"
        :value="true"
        right
        unanchored
        class="snackbar__item"
        @input="!$event && hideMessage(id)"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DeckSnackbar from '~/deck/snackbar';

export default {
  name: 'Snackbar',
  components: {
    DeckSnackbar,
  },
  props: {
    isPublic: { type: Boolean, default: false },
  },
  data() {
    return {
      hasPausedMessages: false,
    };
  },
  channels: {
    UserNotificationChannel: {
      received({ status, message }) {
        this.$notifier.showMessage({
          content: message,
          color: status,
        });
      },
    },
  },
  computed: {
    ...mapState('snackbar', ['messages']),
  },
  watch: {
    messages: {
      async handler() {
        await this.$nextTick();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isPublic) return;
    if (!this.$auth.loggedIn) return;

    this.$zazosCable.subscribe({ channel: 'UserNotificationChannel' });
  },
  methods: {
    ...mapActions('snackbar', ['hideMessage']),
  },
};
</script>

<style lang="scss">
.snackbar {
  --margin: 16px;
  --gap: 8px;

  position: fixed;
  margin: var(--margin);
  height: calc(100vh - calc(var(--margin) * 2));
  width: calc(100vw - calc(var(--margin) * 2));
  pointer-events: none;
  z-index: 1000;
}

.snackbar__wrapper {
  pointer-events: all;
  position: absolute;
  right: 0;
}

.snackbar__item {
  width: fit-content !important;
  padding-bottom: var(--gap) !important;

  &.deck-snackbar--slide-from-right-leave-active {
    position: absolute !important; // for smooth transition when removing a message from the array
    z-index: -1;

    &, .v-snack__wrapper {
      pointer-events: none !important; // to allow clicks on the next message while the current one is still fading out
    }
  }
}
</style>
