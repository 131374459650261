import { marked } from 'marked';

const renderer = new marked.Renderer();
const linkRenderer = renderer.link;
const tokenizer = {
  code() {
    // return undefined to prevent indented text from being treated as code block
    // we support code blocks with 3 backticks only
    return undefined;
  },
};

renderer.link = (href, title, text) => {
  const mailToLink = href.startsWith('mailto:');
  const localLink = href.startsWith(`${window.location.protocol}//${window.location.hostname}`);
  const html = linkRenderer.call(renderer, href, title, text);

  if (mailToLink) return renderer.text.call(renderer, text); // mailto: links are not allowed

  return localLink ? html : html.replace(/^<a /, '<a target="_blank" rel="noreferrer noopener nofollow" ');
};

marked.use({
  tokenizer,
  renderer,
});

export default marked;
