export const state = () => ({
  currentWorkflow: {},
});

export const getters = {
  workflowStepsMap: ({ currentWorkflow }) => {
    const stepsMap = {};

    const collectSteps = (steps) => {
      steps.forEach((step) => {
        stepsMap[step.id] = step;

        if (step.sorted_steps) {
          collectSteps(step.sorted_steps);
        }
      });
    };

    collectSteps(currentWorkflow.sorted_steps);
    return stepsMap;
  },
};

export const mutations = {
  setCurrentWorkflow(state, workflow) {
    state.currentWorkflow = workflow;
  },
};
