import { publishedVersions } from './published-versions';
import { archives } from './archives';

export const views = $axios => ({
  get: (viewId, params = {}) => $axios.$get(`/builder/views/${viewId}`, { params }),
  create: (data = {}) => $axios.$post('/builder/views', data),
  update: (viewId, data = {}) => $axios.$patch(`/builder/views/${viewId}`, data),
  // nested resources
  publishedVersions: publishedVersions($axios),
  archives: archives($axios),
});
