<template>
  <v-icon
    v-bind="$attrs"
    :style="cssProps"
    class="deck-icon"
    :class="{ 'fa-fw': fixedWidth }"
  >
    {{ `fa-${computedKind} fa-${computedName}` }}
  </v-icon>
</template>

<script>
import { camelCase } from 'lodash';
import { COLORS } from '~/assets/javascript/constants';

/**
 * A wrapper around v-icon that uses Font-Awesome icons and allows for custom sizing and coloring.
 */
export default {
  name: 'DeckIcon',
  props: {
    /**
     * The name of the icon from Font-Awesome. Will be prefixed with `fa-` automatically (eg: pencil, xmark, etc...). See https://fontawesome.com/search.
     * @type {string}
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * Size of the icon. Can be a semantic size (small, medium, large), inherit the parent font size (font), or a CSS size (in any unit, eg: 10px, 2rem, 50%, etc...).
     * @type {'small' | 'medium' | 'large' | 'font' | string}
     * @default 'medium'
     */
    size: {
      type: String,
      default: 'medium',
    },

    /**
     * The kind of icon. Will default to 'regular', but will be 'solid' when `size='small'` or explicitly set value otherwise.
     * @type {'regular' | 'solid' | 'brands' | 'kit' | string}
     * @default 'regular'
     */
    kind: {
      type: String,
      default: undefined, // undefined will default to 'regular' in the computed property unless explicitly set
    },

    /**
     * The color of the icon. Choose from preset or any valid CSS color. Inherit parent font color by default.
     * @type { 'currentColor' | 'blue' | 'cyan' | 'teal' | 'green' | 'yellow' | 'orange' | 'red' | 'pink' | 'deepPurple' | 'grey' | string}
     * @default 'currentColor'
     */
    color: {
      type: String,
      default: 'currentColor',
    },

    /**
     * Whether the icon should have a fixed width for balanced alignment. See https://fontawesome.com/docs/web/style/fixed-width.
     * @type {boolean}
     * @default false
     */
    fixedWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssProps() {
      return {
        '--deck-icon-size': this.computedSize,
        '--deck-icon-color': this.computedColor,
      };
    },
    computedSize() {
      if (this.size === 'small') return '12px';
      if (this.size === 'medium') return '16px';
      if (this.size === 'large') return '20px';
      if (this.size === 'font') return '1em';

      return this.size;
    },
    computedKind() {
      if (this.legacyNameFallback?.kind) return this.legacyNameFallback.kind;

      if (this.kind === undefined && this.size === 'small') return 'solid';
      if (this.kind === undefined && this.size !== 'small') return 'regular';

      return this.kind; // override default values
    },
    computedName() {
      if (this.legacyNameFallback?.name) return this.legacyNameFallback.name;

      return this.name;
    },
    availableColors() {
      // in: { colorName: { base: { background: 'colorValue' } }, ... }
      // out: { colorName: 'colorValue', ... }
      const formattedColors = Object.fromEntries(
        Object.keys(COLORS)
          .map(colorName => [colorName, COLORS[colorName].base.background]),
      );

      return { ...formattedColors, ...this.$vuetify.theme.currentTheme };
    },
    computedColor() {
      return this.availableColors[camelCase(this.color)] || this.color;
    },

    legacyNameFallback() {
      /**
       * Today, naming is done with two props: `name` and `kind`. Legacy was a
       * single string composed of two namespaced words: `fa-${kind} fa-${name}`
       * or `fa-${name} fa-${kind}`.
       *
       * We need to detect if we tried to use the legacy naming convention, and
       * return a proper object with name and kind extracted that will be used
       * instead.
       *
       * TODO: This is a temporary solution until we can remove the legacy
       * naming convention safely (specially for cases where we defined icons
       * from the backend).
       *
      **/
      if (!this.name.includes('fa-')) return null;

      const kinds = ['fa-regular', 'fa-solid', 'fa-brands', 'fa-kit'];
      const legacyNameParts = this.name.split(' ').filter(Boolean);

      const name = legacyNameParts.find(part => !kinds.includes(part));
      const kind = legacyNameParts.find(part => kinds.includes(part));

      if (!name || !name.includes('fa-')) {
        return {
          name: 'rectangle-xmark', // Renders an icon that represents a missing icon
          kind: 'regular',
        };
      }

      return {
        name: name.split('fa-')[1].trim(),
        kind: kind?.split('fa-')?.[1]?.trim(), // if kind is undefined, it will eventually inherit the default value in the computed property
      };
    },
  },
};
</script>
<style lang="scss">
.deck-icon {
  font-size: var(--deck-icon-size) !important;
  color: var(--deck-icon-color) !important;

  &.v-icon.fa::before {
    font-size: 1em; // TODO: remove this when deck-icon is used widely instead of v-icon and we remove this global override on `~/assets/styles/overrides/vuetify`
  }
}
</style>
