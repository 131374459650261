import { buildBaseInfoComponent } from '~/assets/javascript/utils/page-state-components/info-components/_base';

export const buildChartInfoComponent = ({
  columns = 1,
  content,
  id,
  label,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    content,
    label,
    type: 'chart',
  };

  if (id) component.id = id;

  return component;
};
