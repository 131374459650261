/* eslint-disable consistent-return */
import Vue from 'vue';
import { camelCase, isEqual, isEmpty, merge, omit, omitBy } from 'lodash';
import { DYNAMIC_DATES, DYNAMIC_RECORDS_TEMPLATE_LINK_VALUES } from '~/assets/javascript/constants';
import {
  fetchFieldsIdsFromInfoComponent,
  fetchMainContentInfoComponentById,
  formatDateDisplayName,
  transformDynamicDate,
  transformDraftRecordData,
  transformRawRecordData,
  removeUnchangedDataFromDraftRecord,
  checkForMissingRequiredFields,
} from '~/assets/javascript/utils';

const loadRecordsByView = (state, { records, subviews, fields_ids: fieldsIds }) => {
  const newRecords = (records || []).reduce((acc, record) => {
    // force field data not present to be undefined
    // it is needed to avoid having stale data in the store
    const recordData = fieldsIds.reduce((acc, fieldId) => {
      acc[fieldId] = record.data[fieldId];
      return acc;
    }, {});

    acc.draftRecordsById[record.id] = {
      id: record.id,
      ...state.draftRecordsById[record.id],
      ...omitBy(recordData, (_value, fieldId) => state.draftRecordFieldsChangedMapping[record.id]?.[fieldId]),
    };

    if (!isEmpty(state.rawRecordsById[record.id])) {
      acc.rawRecordsById[record.id] = acc.draftRecordsById[record.id];
    }

    return acc;
  }, {
    draftRecordsById: {},
    rawRecordsById: {},
  });

  if (!isEmpty(newRecords.draftRecordsById)) {
    state.draftRecordsById = {
      ...state.draftRecordsById,
      ...newRecords.draftRecordsById,
    };
  }

  if (!isEmpty(newRecords.rawRecordsById)) {
    state.rawRecordsById = {
      ...state.rawRecordsById,
      ...newRecords.rawRecordsById,
    };
  }

  subviews?.forEach((subview) => {
    loadRecordsByView(state, subview);
  });
};

export const state = () => ({
  rawRecordsById: {},
  draftRecordsById: {},
  viewFiltersByViewIdAndRecordId: {},
  draftRecordFieldsChangedMapping: {},
  isSaving: false,
  hasNewRecord: false,
});

export const getters = {
  rawRecordById: state => id => state.rawRecordsById[id],
  draftRecordById: state => id => state.draftRecordsById[id],
  viewFiltersByViewIdAndRecordId: state => (viewId, recordId) => state.viewFiltersByViewIdAndRecordId[`${viewId}-${recordId}`] || [],
  hasChangesInDraftRecords: state => Object.keys(state.draftRecordFieldsChangedMapping).length > 0,
};

export const mutations = {
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving;
  },
  updateDraftRecordFieldValue(state, { recordId, fieldId, value }) {
    state.rawRecordsById[recordId] ||= state.draftRecordsById[recordId];

    Vue.set(state.draftRecordsById, recordId, {
      ...state.draftRecordsById[recordId],
      [fieldId]: value,
    });

    Vue.set(state.draftRecordFieldsChangedMapping, recordId, {
      ...state.draftRecordFieldsChangedMapping[recordId],
      [fieldId]: true,
    });
  },
  resetDraftRecordFieldsChangedMapping(state) {
    state.draftRecordFieldsChangedMapping = {};
  },
  loadRecordsByView(state, view) {
    loadRecordsByView(state, view);
  },
  updateViewFilters(state, { viewId, recordId, viewFilters }) {
    Vue.set(state.viewFiltersByViewIdAndRecordId, `${viewId}-${recordId}`, viewFilters);
  },
  buildNewRecord(state, {
    vueContext: {
      newRecordId,
      view: {
        fields,
        records_template: recordsTemplate = [],
        fields_format_options: fieldsFormatOptions,
      },
      initialValues = {},
      $auth: { user },
      $i18n: { locale },
      $route: { query: { sri: sourceRecordId } = {} },
    },
    linkOptionsByFieldId = {},
  }) {
    const computedInitialValues = merge(
      recordsTemplate.reduce((acc, { field_id: fieldId, value }) => {
        const field = fields.find(({ id }) => id === fieldId);

        if (!field) return acc; // TODO: Don't send records_template data that is not present in view

        let valueToSet = value;

        switch (field.type) {
          case 'Link': {
            const foreignRecordIds = [];

            if (value === 'current_user') {
              if (user.guest_record_id) foreignRecordIds.push(user.guest_record_id);
              if (user.people_record_id) foreignRecordIds.push(user.people_record_id);
            } else if (value === 'source_record') {
              if (sourceRecordId) foreignRecordIds.push(sourceRecordId);
            }

            if (foreignRecordIds.length > 0) {
              const foreignRecord = (linkOptionsByFieldId[field.id] || []).find(({ record_id: recordId }) => foreignRecordIds.includes(recordId));

              if (foreignRecord) {
                valueToSet = [{
                  foreign_record_id: foreignRecord.record_id,
                  foreign_record_display_name: foreignRecord.record_display_name,
                }];

                if (foreignRecord.record_data) {
                  [
                    state.draftRecordsById,
                    state.rawRecordsById,
                  ].forEach((stateContent) => {
                    Vue.set(stateContent, foreignRecord.record_id, {
                      id: foreignRecord.record_id,
                      ...foreignRecord.record_data,
                    });
                  });
                }
              } else {
                valueToSet = [];
              }
            } else {
              valueToSet = [];
            }

            break;
          }

          case 'Date':
          case 'DateTime':
            if (DYNAMIC_DATES.map(({ key }) => key).includes(value)) {
              const date = transformDynamicDate(value);

              valueToSet = {
                value: date,
                display_name: formatDateDisplayName(date, {
                  fieldId,
                  fieldsFormatOptions,
                  locale,
                }),
              };
            }

            break;
          case 'Select':
          case 'MultipleSelect':
            valueToSet = (value || []).map((optionId) => {
              const selectOption = field.options.select_options.find(({ id }) => id === optionId);

              return {
                select_option_color: selectOption.color,
                select_option_display_name: selectOption.value,
                select_option_id: selectOption.id,
                select_option_value: selectOption.internal_value,
              };
            });

            break;
          default:
            break;
        }

        return ({
          ...acc,
          [fieldId]: valueToSet,
        });
      }, {}),
      initialValues,
    );

    [
      state.draftRecordsById,
      state.rawRecordsById,
    ].forEach((stateContent) => {
      Vue.set(stateContent, newRecordId, {
        id: newRecordId,
        ...computedInitialValues,
        isNew: true,
      });
    });

    state.hasNewRecord = true;
  },
  resetRecord(state, recordId) {
    if (isEmpty(state.rawRecordsById[recordId])) {
      // TODO: Remove this logic after deploy validation
      this.$rollbar.error('resetRecord: Missing raw record data');
    }

    Vue.set(state.draftRecordsById, recordId, state.rawRecordsById[recordId]);
    Vue.delete(state.draftRecordFieldsChangedMapping, recordId);
  },
  updateRecord(state, { recordId, recordData }) {
    Vue.delete(state.rawRecordsById, recordId);

    Vue.set(state.draftRecordsById, recordId, {
      id: recordId,
      ...recordData,
      ...state.draftRecordsById[recordId],
    });
  },
  reset(state) {
    state.rawRecordsById = {};

    // delete all draft records except the ones that are new
    state.draftRecordsById = Object.values(state.draftRecordsById).reduce((acc, record) => {
      if (record.isNew) acc[record.id] = record;
      return acc;
    }, {});

    state.draftRecordFieldsChangedMapping = {};
  },
  resetViewFilters(state) {
    state.viewFiltersByViewIdAndRecordId = {};
  },
  commitRecordCreated(state, { tempRecordId, recordId, recordData }) {
    Vue.set(state.rawRecordsById, recordId, {
      id: recordId,
      ...recordData,
    });

    Vue.set(state.draftRecordsById, recordId, {
      id: recordId,
      ...recordData,
    });

    delete state.draftRecordsById[tempRecordId];
  },
  markNewRecordFlag(state, flag) {
    state.hasNewRecord = flag;
  },
};

export const actions = {
  async saveRecordById({ state, dispatch }, { recordId, view }) {
    const draftRecord = state.draftRecordsById[recordId];

    if (draftRecord.isNew) {
      return dispatch('createRecordById', { recordId, view });
    }

    return dispatch('updateRecordById', { recordId, view });
  },
  async updateRecordById(
    { getters, rootGetters, commit, dispatch, state },
    {
      recordId,
      view: {
        fields,
        id: viewId,
        permit_record_update: permitRecordUpdate,
        page_type: pageType,
        fields_required: fieldsRequired,
        page_data_default: pageDataDefault,
      },
    },
  ) {
    if (!permitRecordUpdate && !pageDataDefault) {
      this.$rollbar.error('Update record in view without permit_record_update attempt');
      return;
    }

    if (isEmpty(getters.rawRecordById(recordId))) {
      // TODO: Remove this logic after deploy validation
      this.$rollbar.error('Missing raw record data');
      return { id: recordId };
    }

    const rawRecord = transformRawRecordData(getters.rawRecordById(recordId), fields, viewId, rootGetters);
    const draftRecord = transformDraftRecordData(getters.draftRecordById(recordId), fields, viewId, rootGetters);

    if (isEqual(rawRecord, draftRecord)) return { id: recordId };
    checkForMissingRequiredFields(draftRecord, fieldsRequired);

    const params = {
      values: removeUnchangedDataFromDraftRecord(draftRecord, rawRecord, fieldsRequired),
    };

    if (!pageDataDefault) params.view_id = viewId;

    try {
      commit('setIsSaving', true);
      const result = await this.$api.$patch(`/records/${recordId}`, params);

      window.analytics.track(`${camelCase(pageType)}Submission`, { method: 'update' });

      commit('resetDraftRecordFieldsChangedMapping');
      commit('setIsSaving', false);

      if (!pageDataDefault && state.hasNewRecord) {
        await dispatch('view/loadView', {
          recordId,
          viewId,
          refresh: true,
        }, { root: true });

        commit('markNewRecordFlag', false);
      }

      return {
        id: recordId,
        requestId: result.request_id,
      };
    } catch (error) {
      commit('setIsSaving', false);
      throw error;
    }
  },
  async createRecordById(
    { getters, rootGetters, commit },
    {
      recordId,
      view: {
        fields,
        id: viewId,
        permit_record_insert: permitRecordInsert,
        page_type: pageType,
        page_data_default: pageDataDefault,
        fields_required: fieldsRequired,
      },
    },
  ) {
    if (!permitRecordInsert && !pageDataDefault) throw new Error('Insert record in view without permit_record_insert attempt');

    const draftRecord = transformDraftRecordData(getters.draftRecordById(recordId), fields, viewId, rootGetters);
    checkForMissingRequiredFields(draftRecord, fieldsRequired);

    const params = {
      values: omit(draftRecord, ['id']),
    };

    if (!pageDataDefault) params.view_id = viewId;

    try {
      commit('setIsSaving', true);
      const result = await this.$api.$post('/records', params);
      window.analytics.track(`${camelCase(pageType)}Submission`, { method: 'insert' });

      commit('commitRecordCreated', {
        tempRecordId: recordId,
        recordId: result.id,
        recordData: result.data,
      });

      commit('resetDraftRecordFieldsChangedMapping');
      commit('setIsSaving', false);

      return { id: result.id, requestId: result.request_id };
    } catch (error) {
      commit('setIsSaving', false);
      throw error;
    }
  },
  async buildNewRecord({ commit }, vueContext) {
    const {
      view: {
        id: viewId,
        records_template: recordsTemplate = [],
        fields,
      },
      $apiClient,
    } = vueContext;

    const linkOptionsByFieldId = {};

    const populateLinkOptionsPromises = recordsTemplate.reduce((acc, { field_id: fieldId, value }) => {
      const field = fields.find(({ id }) => id === fieldId);

      if (field && field.type === 'Link' && DYNAMIC_RECORDS_TEMPLATE_LINK_VALUES.includes(value)) {
        const apiCall = value === 'current_user'
          ? $apiClient.views.foreignRecords.userLink.get
          : $apiClient.views.foreignRecords.get;

        // TODO: Skip link request is source record is not present
        acc.push((async () => {
          const { linked_records: linkOptions } = await apiCall(viewId, { fieldId, recordId: null });
          linkOptionsByFieldId[fieldId] = linkOptions;
        })());
      }

      return acc;
    }, []);

    await Promise.all(populateLinkOptionsPromises);

    return commit('buildNewRecord', {
      vueContext,
      linkOptionsByFieldId,
    });
  },
  async updateViewFilters({ commit, state, rootGetters }, { viewId, recordId, fields }) {
    const draftRecord = state.draftRecordsById[recordId];

    if (!draftRecord) return;

    const record = {
      ...draftRecord,
      ...transformDraftRecordData(draftRecord, fields, viewId, rootGetters),
    };

    const viewFilters = await this.$api.$post(`/views/${viewId}/view_filters/test`, {
      view_filter_type: 'InfoComponent',
      fields_data: omit(record, ['isNew', 'id']),
      record_id: record.isNew ? null : recordId,
    });

    commit('updateViewFilters', {
      viewId,
      recordId,
      viewFilters,
    });
  },
  async clearFilteredRecordData({ commit, state }, { view, recordId, previousFilters }) {
    if (!previousFilters || isEmpty(previousFilters)) return;

    const rawRecord = state.rawRecordsById[recordId];

    const viewFilters = state.viewFiltersByViewIdAndRecordId[`${view.id}-${recordId}`] || [];
    const previousViewFilterReferenceIds = previousFilters.map(filter => filter.info_component_id);
    const newViewFilterReferenceIds = new Set(viewFilters.map(filter => filter.info_component_id));
    const filteredComponentIds = previousViewFilterReferenceIds.filter(id => !newViewFilterReferenceIds.has(id));

    let fieldIdsToClear = new Set();

    filteredComponentIds.forEach((id) => {
      const infoComponent = fetchMainContentInfoComponentById(view, id);
      const allowedTypes = ['field', 'group']; // Do not clear information from fields used in charts (read-only)
      const fieldIds = fetchFieldsIdsFromInfoComponent(infoComponent, allowedTypes);
      fieldIdsToClear = new Set([...fieldIdsToClear, ...fieldIds]);
    });

    fieldIdsToClear.forEach((fieldId) => {
      commit('updateDraftRecordFieldValue', {
        recordId,
        fieldId,
        value: rawRecord[fieldId],
      });
    });
  },
};
