export const state = () => ({
  undoStack: [],
  redoStack: [],
  requestInProgress: false,
});

export const getters = {
  isRequestBlocked: state => state.requestInProgress,
  isUndoStackEmpty: state => state.undoStack.length === 0,
  isRedoStackEmpty: state => state.redoStack.length === 0,
  currentUndo: state => state.undoStack[state.undoStack.length - 1],
  currentRedo: state => state.redoStack[state.redoStack.length - 1],
};

export const mutations = {
  addToUndoStack(state, uuid) {
    state.undoStack.push(uuid);
  },
  addToRedoStack(state, uuid) {
    state.redoStack.push(uuid);
  },
  popFromUndoStack(state) {
    state.undoStack.pop();
  },
  popFromRedoStack(state) {
    state.redoStack.pop();
  },
  clearRedoStack(state) {
    state.redoStack = [];
  },
  blockRequests(state, flag) {
    state.requestInProgress = flag;
  },
};

export const actions = {
  addRequestToUndoStack({ commit }, requestId) {
    commit('addToUndoStack', requestId);
    commit('clearRedoStack');
  },
  async undoRequest({ commit, getters }) {
    if (getters.isUndoStackEmpty) return;
    if (getters.isRequestBlocked) return;

    commit('blockRequests', true);

    const requestId = getters.currentUndo;

    try {
      const { request_id: newRequestId } = await this.$api.$post(`requests/${requestId}/undo`);
      commit('popFromUndoStack');
      commit('addToRedoStack', newRequestId);
    } catch (error) {
      this.$rollbar.error('error on undoRequest', error);
    }

    commit('blockRequests', false);
  },

  async redoRequest({ commit, getters }) {
    if (getters.isRedoStackEmpty) return;
    if (getters.isRequestBlocked) return;

    commit('blockRequests', true);

    const requestId = getters.currentRedo;

    try {
      const { request_id: newRequestId } = await this.$api.$post(`requests/${requestId}/undo`);
      commit('popFromRedoStack');
      commit('addToUndoStack', newRequestId);
    } catch (error) {
      this.$rollbar.error('error on redoRequest', error);
    }

    commit('blockRequests', false);
  },
};
